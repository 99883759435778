import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
	baseURL: `${baseURL}`,
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
	timeout: 6000,
});

export const set_instance_token = (token: string) => {
	instance.defaults.headers.authorization = `Token ${token}`;
};

export const unset_instance_token = () => {
	instance.defaults.headers.authorization = "";
};

export default instance;
