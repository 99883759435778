import { useContext, useEffect, useRef } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./routes/home/Home";
import Navbar from "./components/navigation/navbar/Navbar";
import Login from "./routes/auth/Login";
import Register from "./routes/auth/Register";
import AuthContext from "./contexts/AuthContext";
import ResetPassword from "./routes/auth/ResetPassword";
import Account from "./routes/account/Account";
import Settings from "./routes/settings/Settings";
import TermsOfUse from "./routes/legal/TermsOfUse";
import PrivacyPolicy from "./routes/legal/PrivacyPolicy";
import RegisterOk from "./routes/auth/RegisterOk";
import EmailVerified from "./routes/auth/EmailVerified";
import NeedVerifyEmail from "./routes/auth/NeedVerifyEmail";
import { DispatchProps, StateProps } from "./store";
import { mapDispatchToProps } from "./store/dispatcher";
import { connect } from "react-redux";
import { App as AntdApp } from "antd";
import Device from "./routes/device/Device";
import ResetPasswordValidation from "./routes/auth/ResetPasswordValidation";


const App = ({
  fetchDevices,
  fetchHomes,
}: {
  fetchDevices: any;
  fetchHomes: any;
}) => {
  const { user } = useContext(AuthContext);
  const loaded = useRef<string | null>(null);

  const focus = (e: any) => {
    if (
      user.is_login &&
      document.visibilityState === "visible"
    ) {
        fetchDevices();
        fetchHomes();
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", focus);
    return () => {
      window.removeEventListener("visibilitychange", focus);
    };
  }, []);

  useEffect(() => {
    if (user.is_login && loaded.current !== user.email) {
      loaded.current = user.email;
      fetchDevices();
      fetchHomes();
    }
  }, [user]);

  return (
    <BrowserRouter>
      {user.is_login && (
        <div className="main-container">
          <Navbar />
          <div className="main-content">
            <AntdApp>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/account" element={<Account />} />
                <Route path="/home/:home_id/settings" element={<Settings />} />
                <Route
                  path="/home/:home_id/:device_id/settings"
                  element={<Settings />}
                />
                <Route path="/home/:home_id/:device_id" element={<Device />} />
                <Route path="/auth/*" element={<Navigate to={"/"} />} />
              </Routes>
            </AntdApp>
          </div>
        </div>
      )}
      {!user.is_login && (
        <AntdApp>
          <Routes>
            <Route
              path="/terms-conditions"
              element={<TermsOfUse />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route path="/auth/login" element={ <Login /> } />
            <Route path="/auth/register" element={<Register />} />
            <Route path="/auth/email-verified" element={<EmailVerified />} />
            <Route
              path="/auth/email-verification"
              element={<NeedVerifyEmail />}
            />
            <Route
              path="/auth/registration-successful"
              element={<RegisterOk />}
            />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route
              path="/auth/reset-password-validation"
              element={<ResetPasswordValidation />}
            />
            <Route path="*" element={<Navigate to={"/auth/login"} />} />
          </Routes>
        </AntdApp>
      )}
    </BrowserRouter>
  );
};

export default connect<StateProps, DispatchProps>(
  null,
  mapDispatchToProps
)(App);
