import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import AuthContext from "../../contexts/AuthContext";
import AuthDecorator from "../../components/auth/auth_decorator/AuthDecorator";
import { App, Input } from "antd";
import MainBtn from "../../components/main/main_btn/MainBtn";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation("auth");
  const { login } = useContext(AuthContext);
  const { notification } = App.useApp();
  const form_ref = useRef<HTMLFormElement | null>(null);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const submit_login = async () => {
    setLoading(true);
    const successful = await login(formData.email, formData.password);
    if (!successful) {
      notification.error({
        message: t("messages:login_error_message"),
        description: t("messages:login_error_description"),
        placement: "topLeft",
      });
    }
    setLoading(false);
  };

  return (
    <div className="auth-page main-page">
      <section>
        <div className="container">
          <div className="form-container">
            <h1 dangerouslySetInnerHTML={{ __html: t("login_title") }} />
            <p className="main-description">{t("login_description")}</p>
            <form ref={form_ref}>
              <label htmlFor="email-input">{t("email_label")}</label>
              <Input
                size="small"
                value={formData.email}
                required
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
                placeholder={t("email_placeholder")}
                type="email"
                autoComplete="email"
                id="email-input"
              />
              <label htmlFor="password-input">{t("password_label")}</label>
              <Input.Password
                size="small"
                value={formData.password}
                required
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  })
                }
                placeholder={t("password_placeholder")}
                type="password"
                autoComplete="password"
                id="password-input"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    formData.email !== "" &&
                    formData.password !== ""
                  ) {
                    submit_login();
                  }
                }}
              />
              <div className="row">
                <span></span>
                <div className="form-row">
                  <span></span>
                  <Link className="primary-link" to={"/auth/reset-password"}>
                    {t("forgot_password")}
                  </Link>
                </div>
              </div>

              <MainBtn
                form={form_ref}
                loading={loading}
                title={t("login_btn")}
                onClick={submit_login}
                disabled={formData.email === "" || formData.password === ""}
              />
            </form>
            <p className="swap-auth-text">
              {t("not_any_account_yet")}{" "}
              <Link className="primary-link" to={"/auth/register"}>
                {t("register_now")}
              </Link>
            </p>
          </div>
          <AuthDecorator />
        </div>
      </section>
    </div>
  );
};

export default Login;
