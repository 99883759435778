import React from "react";
import { useParams } from "react-router-dom";
import DefaultViewHeader from "../../components/main/default_view_header/DefaultViewHeader";
import "./style.scss";
import SettingsAside from "../../components/settings/settings_aside/SettingsAside";
import DeviceSettings from "../../components/settings/settings_block/DeviceSettings";
import HomeSettings from "../../components/settings/settings_block/HomeSettings";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { connect } from "react-redux";
import { DispatchProps, StateProps } from "../../store";
import { Device } from "../../lib/Device";
import { Home } from "../../lib/Home";
import { useTranslation } from "react-i18next";

const Settings = ({ devices, homes }: { devices: Device[]; homes: Home[] }) => {
  const { home_id, device_id } = useParams();
  const { t } = useTranslation("settings");

  return (
    <div id="settings-view">
      <DefaultViewHeader
        title={
          device_id ? t("settings_device_title") : t("settings_home_title")
        }
        description={
          device_id
            ? t("settings_device_description")
            : t("settings_home_description")
        }
      />
      <div className="settings-content">
        <SettingsAside />
        {device_id &&
          devices &&
          (devices.filter((device) => device.id === parseInt(device_id))
            .length !== 0 ? (
            <DeviceSettings
              device={
                devices.filter((device) => device.id === parseInt(device_id))[0]
              }
            />
          ) : (
            <div className="empty-item">
              <span>{t("device_not_found")}</span>
            </div>
          ))}
        {!device_id &&
          home_id &&
          homes &&
          (homes.filter((home) => home.id === parseInt(home_id)).length !==
          0 ? (
            <HomeSettings
              devices={devices}
              home={homes.filter((home) => home.id === parseInt(home_id))[0]}
            />
          ) : (
            <div className="empty-item">
              <span>{t("home_not_found")}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
