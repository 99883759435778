import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultViewHeader from "../../components/main/default_view_header/DefaultViewHeader";
import MainBtn from "../../components/main/main_btn/MainBtn";
import "./style.scss";
import { App, Input, Select } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import AuthContext from "../../contexts/AuthContext";

const Account = () => {
  const { t } = useTranslation("home");
  const { user, update_user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { notification } = App.useApp();
  const [formData, setFormData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    language: user.language,
    current_password: "",
    new_password: "",
  });

  const can_save = useMemo(
    () =>
      JSON.stringify(formData) !==
      JSON.stringify({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        language: user.language,
        current_password: "",
        new_password: "",
      }),
    [formData, user]
  );

  const handle_update = async () => {
    setLoading(true);
    const message = await update_user(formData);

    if (message.type === "error") {
      notification.error({
        message: message.message,
        description: message.description,
        placement: "topLeft",
      });
    } else {
      notification.success({
        message: message.message,
        description: message.description,
        placement: "topLeft",
      });
    }

    setLoading(false);
  };

  const handle_reset = () => {
    setFormData({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      language: user.language,
      current_password: "",
      new_password: "",
    });
  };

  useEffect(() => {
    handle_reset();
  }, [user]);

  return (
    <div className="account-main-view">
      <DefaultViewHeader
        title={t("account_title")}
        description={t("account_description")}
      />
      <div className="center-view">
        <form className="center-view-form classic-form">
          <div className="input-side">
            <div className="input-block">
              <label htmlFor="language-selector">
                {t("site_language_label")}
              </label>
              <Select
                id="language-selector"
                // defaultValue={i18n.language.split("-")[0]}
                // onChange={(e) => i18n.changeLanguage(e)}
                value={formData.language}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    language: e,
                  })
                }
              >
                <Select.Option value="fr">Francais</Select.Option>
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="de">Deutsch</Select.Option>
                <Select.Option value="nl">Nederlands</Select.Option>
              </Select>
            </div>
            <div className="row">
              <div className="input-block">
                <label htmlFor="first-name-input">
                  {t("first_name_label")}
                </label>
                <Input
                  size="small"
                  value={formData.first_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      first_name: e.target.value,
                    })
                  }
                  placeholder={t("first_name_placeholder")}
                  type="text"
                  autoComplete="given-name"
                  id="first-name-input"
                />
              </div>
              <div className="input-block">
                <label htmlFor="last-name-input"> {t("last_name_label")}</label>
                <Input
                  size="small"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      last_name: e.target.value,
                    })
                  }
                  placeholder={t("last_name_placeholder")}
                  type="text"
                  autoComplete="family-name"
                  id="last-name-input"
                />
              </div>
            </div>
          </div>
          <div className="input-side">
            <div className="input-block">
              <label htmlFor="email-input">{t("email_label")}</label>
              <Input
                size="small"
                value={formData.email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
                disabled={true}
                placeholder={t("email_placeholder")}
                type="email"
                autoComplete="email"
                id="email-input"
              />
            </div>
            <div className="input-block">
              <label htmlFor="password-input">
                {t("current_password_label")}
              </label>
              <Input.Password
                size="small"
                value={formData.current_password}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    current_password: e.target.value,
                  })
                }
                placeholder={t("current_password_placeholder")}
                type="password"
                autoComplete="password"
                id="password-input"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>

            <div className="input-block">
              <label htmlFor="password-confirmation-input">
                {t("new_password_label")}
              </label>
              <Input.Password
                size="small"
                value={formData.new_password}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    new_password: e.target.value,
                  })
                }
                placeholder={t("new_password_placeholder")}
                type="password"
                autoComplete="password"
                id="password-confirmation-input"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
          </div>
        </form>
        <div className="center-view-form-submit">
          <MainBtn
            title={t("save_updates")}
            onClick={handle_update}
            disabled={!can_save}
            loading={loading}
          />
          <MainBtn
            title={t("main:cancel")}
            color="secondary"
            onClick={handle_reset}
            disabled={!can_save}
          />
        </div>
      </div>
    </div>
  );
};

export default Account;
