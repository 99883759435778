import { useMemo } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import { FIELD_NAME_TO_TRANSLATION_LABEL, LineChartProps, timestampToReadable } from "./LineChartCommon";


const DropoutLineChart = ({ lineData, currentDevice, ticks, lineChartXTickFormatter }: LineChartProps) => {
    
    const { t } = useTranslation("dashboard")

    const renderCustomizedTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const pt = lineData.find((elem: any) => elem.time === label);
            if (pt) {
                return (
                <div className="tooltip-container">
                    <p style={{ fontWeight: 700 }}>{timestampToReadable(parseInt(pt.time))}</p>
                    {payload.map((line: any, index: number) => (
                    <p key={index} style={{ color: line.stroke, fontWeight: 500 }}>
                        {t(line.name, { device_name: currentDevice?.name })}:{" "}
                            { pt[line.dataKey] } {line.dataKey !== "soliseco_power"  ? "V" : "W" }
                    </p>
                    ))}
                </div>
                );
            }
        }
        return <></>;
    };

    const renderColorfulLegendText = (value: string, entry: any) => {
        const { color } = entry;
        return <span style={{ color: color }}>{t(value, { device_name: currentDevice?.name })}</span>;
    };

    const [pDataMin, pDataMax] = useMemo(() => {
        const globalMin = Math.min(...lineData.map((pt: any) => pt.soliseco_power))
        const globalMax = Math.max(...lineData.map((pt: any) => pt.soliseco_power))
        const min: number = Math.round((globalMin * 1.1))
        const roundedMin = min - (10 + (min % 10))
        const max: number = Math.round((globalMax * 1.1))
        const roundedMax =  max + (10 - (max % 10))
        return [Math.min(-50, parseInt(roundedMin.toFixed(0))), Math.max(50, parseInt(roundedMax.toFixed(0)))]
    }, [lineData])

        const compareNumbers = (a: number, b: number) => {
        return a - b;
    }
    
    const pYTicks = useMemo(() => {
        const interval = Math.round((Math.abs(pDataMin) + Math.abs(pDataMax)) / 3)
        const roundedInterval = interval + (10 - (interval % 10))
        const negativeTickCnt: number = Math.floor(Math.abs(pDataMin) / roundedInterval)
        const positiveTickCnt: number = Math.floor(Math.abs(pDataMax) / roundedInterval)
        const negativeTicks = Array.from({ length: negativeTickCnt }, (_, i) => { return -(i + 1) * roundedInterval })
        const positiveTicks = Array.from({ length: positiveTickCnt }, (_, i) => { return (i + 1) * roundedInterval })
        const ticks = [pDataMin, ...negativeTicks, 0, ...positiveTicks, pDataMax]
        const uniqueTicks = ticks.filter((value, index, array) => ticks.indexOf(value) === index);
        uniqueTicks.sort(compareNumbers)
        return uniqueTicks
    }, [pDataMin, pDataMax])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={500}
                height={300}
                data={lineData}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey='time'
                    name='Time'
                    scale='time'
                    type='number'
                    domain={['dataMin', 'dataMax']} 
                    tickFormatter={ lineChartXTickFormatter }
                    ticks={ticks}
                />
                <YAxis
                    yAxisId="voltage"
                    unit="V"
                    ticks={[220, 230, 240, 250, 260]}
                    domain={ [220, 260] }
                    allowDataOverflow
                />
                <YAxis
                    yAxisId="power"
                    orientation="right"
                    unit="W"
                    domain={ () => [pDataMin, pDataMax] }
                    ticks={ pYTicks }
                />
                <Legend formatter={renderColorfulLegendText} />
                <Tooltip content={renderCustomizedTooltip}/>
                <Legend
                    formatter={(value) =>
                    t(FIELD_NAME_TO_TRANSLATION_LABEL[value], {
                        device_name: currentDevice?.name,
                    })
                    }
                />
                { currentDevice?.installation_phase === 1 && <Line
                    type="monotone"
                    yAxisId="voltage"
                    dataKey="u_1"
                    stroke="#3b6bb8"
                    activeDot={ { r: 4 } }
                    dot={ false }
                /> }
                { currentDevice?.installation_phase === 2 && <Line
                    type="monotone"
                    yAxisId="voltage"
                    dataKey="u_2"
                    stroke="#3b6bb8"
                    activeDot={ { r: 4 } }
                    dot={ false }
                /> }
                { currentDevice?.installation_phase === 3 && <Line
                    type="monotone"
                    yAxisId="voltage"
                    dataKey="u_3"
                    stroke="#3b6bb8"
                    activeDot={ { r: 4 } }
                    dot={ false }
                /> }
                <Line
                    type="monotone"
                    yAxisId="power"
                    dataKey={ "soliseco_power" }
                    stroke="#82BA71"
                    activeDot={ { r: 4 } }
                    dot={ false }
                />
                <Line
                    type="monotone"
                    yAxisId="voltage"
                    dataKey="dropout_v_max"
                    stroke="#012124"
                    activeDot={ { r: 4 } }
                    dot={ false }
                />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default DropoutLineChart
