import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./slices/dashboard";
import devicesSlice from "./slices/devices";
import homesSlice from "./slices/homes";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    devices: devicesSlice,
    homes: homesSlice,
  },
});

export interface StateProps {
  dashboard: any;
  devices: any;
  homes: any;
}

export interface DispatchProps {
  fetchDashboard: (data: any) => void;
  fetchDevices: (data: any) => void;
  createDevice: (data: any) => void;
  updateDevice: (data: any) => void;
  deleteDevice: (data: any) => void;
  fetchHomes: (data: any) => void;
  createHome: (data: any) => void;
  updateHome: (data: any) => void;
  deleteHome: (data: any) => void;
}

export type ReduxProps = StateProps & DispatchProps;

export type RootState = ReturnType<typeof store.getState>;

export default store;
