const PrivacyPolicy = () => {
	return (
        <div style={{padding: 50}}>
            <div>
                <strong>
                <span style={{ fontSize: 26 }}>
                    <span data-custom-class="title">
                    <bdt className="block-component" />
                    <bdt className="question">PRIVACY POLICY</bdt>
                    <bdt className="statement-end-if-in-editor" />
                    </span>
                </span>
                </strong>
            </div>
            <div>
                <br />
            </div>
            <div>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                    <span style={{ fontSize: 15 }}>
                    <span data-custom-class="subtitle">
                        Last updated <bdt className="question">June 20, 2024</bdt>
                    </span>
                    </span>
                </strong>
                </span>
            </div>
            <div>
                <br />
            </div>
            <div>
                <br />
            </div>
            <div>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                    This privacy notice for{" "}
                    <bdt className="question">
                        Metop Engineering SRL
                        <bdt className="block-component" />
                    </bdt>{" "}
                    (<bdt className="block-component" />'<strong>we</strong>', '
                    <strong>us</strong>', or '<strong>our</strong>'
                    <bdt className="else-block" />
                    </span>
                    <span data-custom-class="body_text">
                    ), describes how and why we might collect, store, use, and/or share (
                    <bdt className="block-component" />'<strong>process</strong>'
                    <bdt className="else-block" />) your information when you use our
                    services (<bdt className="block-component" />'
                    <strong>Services</strong>'<bdt className="else-block" />
                    ), such as when you:
                    </span>
                </span>
                </span>
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        Visit our website
                        <bdt className="block-component" /> at{" "}
                        <span style={{ color: "rgb(0, 58, 250)" }}>
                        <bdt className="question">
                            <a
                            href="https://www.my.soliseco.com"
                            target="_blank"
                            data-custom-class="link"
                            >
                            https://www.my.soliseco.com
                            </a>
                        </bdt>
                        </span>
                        <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor">
                                    , or any website of ours that links to this privacy
                                    notice
                                </bdt>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div>
                <bdt className="block-component">
                <span style={{ fontSize: 15 }}>
                    <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            </span>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </bdt>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        Download and use
                        <bdt className="block-component" /> our mobile application
                        <bdt className="block-component" /> (
                        <bdt className="question">
                        My Soliseco)
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <bdt className="statement-end-if-in-editor">
                                            ,
                                        </bdt>
                                        </span>
                                    </span>
                                    </span>
                                </span>
                                </span>
                            </span>
                            </span>
                        </span>
                        </bdt>
                    </span>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component">
                                    {" "}
                                    or any other application of ours that links to this
                                    privacy notice
                                    </bdt>
                                </bdt>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        Engage with us in other related ways, including any sales,
                        marketing, or events
                        <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                    <strong>Questions or concerns? </strong>Reading this privacy notice
                    will help you understand your privacy rights and choices. If you do
                    not agree with our policies and practices, please do not use our
                    Services.
                    <bdt className="block-component" /> If you still have any questions or
                    concerns, please contact us at{" "}
                    <bdt className="question">info@soliseco.com</bdt>.
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <strong>
                <span style={{ fontSize: 15 }}>
                    <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                </span>
                </strong>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>
                    <em>
                        This summary provides key points from our privacy notice, but you
                        can find out more details about any of these topics by clicking the
                        link following each key point or by using our{" "}
                    </em>
                    </strong>
                </span>
                </span>
                <a data-custom-class="link" href="#toc">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                    <strong>
                        <em>table of contents</em>
                    </strong>
                    </span>
                </span>
                </a>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>
                    <em> below to find the section you are looking for.</em>
                    </strong>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>What personal information do we process?</strong> When you
                    visit, use, or navigate our Services, we may process personal
                    information depending on how you interact with us and the Services, the
                    choices you make, and the products and features you use. Learn more
                    about{" "}
                </span>
                </span>
                <a data-custom-class="link" href="#personalinfo">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                    personal information you disclose to us
                    </span>
                </span>
                </a>
                <span data-custom-class="body_text">.</span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>Do we process any sensitive personal information?</strong>{" "}
                    <bdt className="block-component" />
                    We do not process sensitive personal information.
                    <bdt className="else-block" />
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>Do we collect any information from third parties?</strong>{" "}
                    <bdt className="block-component" />
                    We do not collect any information from third parties.
                    <bdt className="else-block" />
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>How do we process your information?</strong> We process your
                    information to provide, improve, and administer our Services,
                    communicate with you, for security and fraud prevention, and to comply
                    with law. We may also process your information for other purposes with
                    your consent. We process your information only when we have a valid
                    legal reason to do so. Learn more about{" "}
                </span>
                </span>
                <a data-custom-class="link" href="#infouse">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                    how we process your information
                    </span>
                </span>
                </a>
                <span data-custom-class="body_text">.</span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>
                    In what situations and with which <bdt className="block-component" />
                    parties do we share personal information?
                    </strong>{" "}
                    We may share information in specific situations and with specific{" "}
                    <bdt className="block-component" />
                    third parties. Learn more about{" "}
                </span>
                </span>
                <a data-custom-class="link" href="#whoshare">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                    when and with whom we share your personal information
                    </span>
                </span>
                </a>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    .<bdt className="block-component" />
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>How do we keep your information safe?</strong> We have{" "}
                    <bdt className="block-component" />
                    organisational
                    <bdt className="else-block" /> and technical processes and procedures in
                    place to protect your personal information. However, no electronic
                    transmission over the internet or information storage technology can be
                    guaranteed to be 100% secure, so we cannot promise or guarantee that
                    hackers, cybercriminals, or other <bdt className="block-component" />
                    unauthorised
                    <bdt className="else-block" /> third parties will not be able to defeat
                    our security and improperly collect, access, steal, or modify your
                    information. Learn more about{" "}
                </span>
                </span>
                <a data-custom-class="link" href="#infosafe">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                    how we keep your information safe
                    </span>
                </span>
                </a>
                <span data-custom-class="body_text">.</span>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>What are your rights?</strong> Depending on where you are
                    located geographically, the applicable privacy law may mean you have
                    certain rights regarding your personal information. Learn more about{" "}
                </span>
                </span>
                <a data-custom-class="link" href="#privacyrights">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">your privacy rights</span>
                </span>
                </a>
                <span data-custom-class="body_text">.</span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>How do you exercise your rights?</strong> The easiest way to
                    exercise your rights is by{" "}
                    <bdt className="block-component">submitting a </bdt>
                </span>
                </span>
                <a
                data-custom-class="link"
                href="https://app.termly.io/notify/082c38b9-d4ab-4cc3-8974-3822b4794494"
                rel="noopener noreferrer"
                target="_blank"
                >
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">data subject access request</span>
                </span>
                </a>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <bdt className="block-component" />, or by contacting us. We will
                    consider and act upon any request in accordance with applicable data
                    protection laws.
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    Want to learn more about what we do with any information we collect?{" "}
                </span>
                </span>
                <a data-custom-class="link" href="#toc">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                    Review the privacy notice in full
                    </span>
                </span>
                </a>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">.</span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div id="toc" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                        <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                    </strong>
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infocollect">
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                </a>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infouse">
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                    <bdt className="block-component" />
                    </span>
                </a>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#legalbases">
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                    3.{" "}
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(0, 58, 250)" }}>
                        WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                        INFORMATION?
                        </span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                    </span>
                </a>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                    <a data-custom-class="link" href="#whoshare">
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                </span>
                <span data-custom-class="body_text">
                    <bdt className="block-component" />
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <a data-custom-class="link" href="#3pwebsites">
                    5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                    <bdt className="statement-end-if-in-editor" />
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                        </span>
                    </span>
                    <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component" />
                            </span>
                        </span>
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#inforetain">
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                    6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component" />
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infosafe">
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                    7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                    <a data-custom-class="link" href="#privacyrights">
                    8. WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#DNT">
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                    9. CONTROLS FOR DO-NOT-TRACK FEATURES
                    <bdt className="block-component" />
                    </span>
                    <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                    </span>
                    </bdt>
                </a>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#DNT">
                <bdt className="block-component">
                    <span style={{ fontSize: 15 }} />
                </bdt>
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#DNT">
                <span style={{ fontSize: 15 }} />
                </a>
                <a data-custom-class="link" href="#policyupdates">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                    10. DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
                </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#contact">
                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                    11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
                </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#request">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                    12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </span>
                </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div id="infocollect" style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                    <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                            <span data-custom-class="heading_1">
                            1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                        </strong>
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div id="personalinfo" style={{ lineHeight: "1.5" }}>
                <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                <span style={{ fontSize: 15 }}>
                    <strong>Personal information you disclose to us</strong>
                </span>
                </span>
            </div>
            <div>
                <div>
                <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <strong>
                                <em>In Short:</em>
                            </strong>
                            </span>
                        </span>
                        </span>
                    </span>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <strong>
                                <em> </em>
                            </strong>
                            <em>
                                We collect personal information that you provide to us.
                            </em>
                            </span>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    We collect personal information that you voluntarily provide to us
                    when you{" "}
                    <span style={{ fontSize: 15 }}>
                        <bdt className="block-component" />
                    </span>
                    register on the Services,{" "}
                    </span>
                    <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <bdt className="statement-end-if-in-editor" />
                        </span>
                    </span>
                    <span data-custom-class="body_text">
                        express an interest in obtaining information about us or our
                        products and Services, when you participate in activities on the
                        Services, or otherwise when you contact us.
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <bdt className="block-component" />
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <strong>Personal Information Provided by You.</strong> The personal
                    information that we collect depends on the context of your
                    interactions with us and the Services, the choices you make, and the
                    products and features you use. The personal information we collect may
                    include the following:
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="question">names</bdt>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="question">phone numbers</bdt>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="question">email addresses</bdt>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="question">usernames</bdt>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="question">passwords</bdt>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="question">billing addresses</bdt>
                        </span>
                        </span>
                    </span>
                    </span>
                </span>
                </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        </span>
                        <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <strong>Sensitive Information.</strong>{" "}
                    <bdt className="block-component" />
                    We do not process sensitive information.
                </span>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                    <bdt className="else-block" />
                </span>
                </span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <bdt className="block-component">
                            <bdt className="block-component" />
                        </bdt>
                        </span>
                    </span>
                    </span>
                </span>
                <bdt className="block-component">
                    <bdt className="block-component" />
                </bdt>
                </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                    <strong>Application Data.</strong> If you use our application(s), we
                    also may collect the following information if you choose to provide us
                    with access or permission:
                    <bdt className="block-component" />
                </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                    </span>
                </bdt>
                </div>
                <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                        <em>Mobile Device Access.</em> We may request access or permission
                        to certain features from your mobile device, including your mobile
                        device's <bdt className="forloop-component" />
                        <bdt className="question">bluetooth</bdt>,{" "}
                        <bdt className="forloop-component" />
                        and other features. If you wish to change our access or permissions,
                        you may do so in your device's settings.
                        <bdt className="statement-end-if-in-editor" />
                    </span>
                    </span>
                </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                    </span>
                </bdt>
                <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                    </span>
                    </bdt>
                </div>
                <ul>
                    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <em>Push Notifications.</em> We may request to send you push
                        notifications regarding your account or certain features of the
                        application(s). If you wish to opt out from receiving these types
                        of communications, you may turn them off in your device's
                        settings.
                        <bdt className="statement-end-if-in-editor" />
                        </span>
                    </span>
                    </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                        This information is primarily needed to maintain the security and
                        operation of our application(s), for troubleshooting, and for our
                        internal analytics and reporting purposes.
                    </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <bdt className="statement-end-if-in-editor">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                    </span>
                    </bdt>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        All personal information that you provide to us must be true,
                        complete, and accurate, and you must notify us of any changes to
                        such personal information.
                        </span>
                    </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                    <span style={{ fontSize: 15 }}>
                        <strong>Information automatically collected</strong>
                    </span>
                    </span>
                </div>
                <div>
                    <div>
                    <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <strong>
                                    <em>In Short:</em>
                                </strong>
                                </span>
                            </span>
                            </span>
                        </span>
                        <span data-custom-class="body_text">
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <strong>
                                    <em> </em>
                                </strong>
                                <em>
                                    Some information — such as your Internet Protocol (IP)
                                    address and/or browser and device characteristics — is
                                    collected automatically when you visit our Services.
                                </em>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        We automatically collect certain information when you visit, use,
                        or navigate the Services. This information does not reveal your
                        specific identity (like your name or contact information) but may
                        include device and usage information, such as your IP address,
                        browser and device characteristics, operating system, language
                        preferences, referring URLs, device name, country, location,
                        information about how and when you use our Services, and other
                        technical information. This information is primarily needed to
                        maintain the security and operation of our Services, and for our
                        internal analytics and reporting purposes.
                        </span>
                    </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        </span>
                    </span>
                    <span data-custom-class="body_text">
                        <bdt className="block-component" />
                    </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        The information we collect includes:
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </div>
                <ul>
                    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                            <em>Log and Usage Data.</em> Log and usage data is
                            service-related, diagnostic, usage, and performance information
                            our servers automatically collect when you access or use our
                            Services and which we record in log files. Depending on how you
                            interact with us, this log data may include your IP address,
                            device information, browser type, and settings and information
                            about your activity in the Services
                            <span style={{ fontSize: 15 }}> </span>(such as the date/time
                            stamps associated with your usage, pages and files viewed,
                            searches, and other actions you take such as which features you
                            use), device event information (such as system activity, error
                            reports (sometimes called <bdt className="block-component" />
                            'crash dumps'
                            <bdt className="else-block" />
                            ), and hardware settings).
                            <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="statement-end-if-in-editor" />
                                    </span>
                                </span>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                    </span>
                    </bdt>
                </div>
                <ul>
                    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                            <em>Device Data.</em> We collect device data such as information
                            about your computer, phone, tablet, or other device you use to
                            access the Services. Depending on the device used, this device
                            data may include information such as your IP address (or proxy
                            server), device and application identification numbers,
                            location, browser type, hardware model, Internet service
                            provider and/or mobile carrier, operating system, and system
                            configuration information.
                            <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="statement-end-if-in-editor" />
                                    </span>
                                </span>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                    </span>
                    </bdt>
                </div>
                <ul>
                    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                            <em>Location Data.</em> We collect location data such as
                            information about your device's location, which can be either
                            precise or imprecise. How much information we collect depends on
                            the type and settings of the device you use to access the
                            Services. For example, we may use GPS and other technologies to
                            collect geolocation data that tells us your current location
                            (based on your IP address). You can opt out of allowing us to
                            collect this information either by refusing access to the
                            information or by disabling your Location setting on your
                            device. However, if you choose to opt out, you may not be able
                            to use certain aspects of the Services.
                            <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="statement-end-if-in-editor" />
                                    </span>
                                </span>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </li>
                </ul>
                <div>
                    <bdt className="block-component">
                    <span style={{ fontSize: 15 }} />
                    </bdt>
                    <bdt className="statement-end-if-in-editor" />
                    <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                            <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component" />
                            </bdt>
                            </span>
                        </span>
                        </span>
                    </span>
                    </span>
                    <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                        <bdt className="block-component" />
                    </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div id="infouse" style={{ lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                            <strong>
                                <span data-custom-class="heading_1">
                                2. HOW DO WE PROCESS YOUR INFORMATION?
                                </span>
                            </strong>
                            </span>
                        </span>
                        </span>
                    </span>
                    </span>
                </div>
                <div>
                    <div style={{ lineHeight: "1.5" }}>
                    <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <strong>
                                    <em>In Short: </em>
                                </strong>
                                <em>
                                    We process your information to provide, improve, and
                                    administer our Services, communicate with you, for
                                    security and fraud prevention, and to comply with law.
                                    We may also process your information for other purposes
                                    with your consent.
                                </em>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        <strong>
                            We process your personal information for a variety of reasons,
                            depending on how you interact with our Services, including:
                        </strong>
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </div>
                <ul>
                    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                            <strong>
                            To facilitate account creation and authentication and
                            otherwise manage user accounts.{" "}
                            </strong>
                            We may process your information so you can create and log in to
                            your account, as well as keep your account in working order.
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                            <bdt className="statement-end-if-in-editor" />
                                            </span>
                                        </span>
                                        </span>
                                    </span>
                                    </span>
                                </span>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </span>
                    </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                            <bdt className="block-component" />
                        </span>
                        </span>
                    </span>
                    </div>
                    <ul>
                    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <strong>
                                To deliver and facilitate delivery of services to the user.{" "}
                            </strong>
                            We may process your information to provide you with the
                            requested service.
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span
                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                    >
                                    <span
                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                    >
                                        <span data-custom-class="body_text">
                                        <span
                                            style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            <span
                                            style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                            }}
                                            >
                                            <span data-custom-class="body_text">
                                                <span
                                                style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                                >
                                                <span
                                                    style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span data-custom-class="body_text">
                                                    <span style={{ fontSize: 15 }}>
                                                        <span
                                                        style={{
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                        >
                                                        <span data-custom-class="body_text">
                                                            <span style={{ fontSize: 15 }}>
                                                            <span
                                                                style={{
                                                                color: "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                                <span data-custom-class="body_text">
                                                                <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                            </span>
                                                            </span>
                                                        </span>
                                                        </span>
                                                    </span>
                                                    </span>
                                                </span>
                                                </span>
                                            </span>
                                            </span>
                                        </span>
                                        </span>
                                    </span>
                                    </span>
                                </span>
                                </span>
                            </span>
                            </span>
                        </span>
                        </span>
                    </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                            <bdt className="block-component" />
                        </span>
                        </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            </span>
                        </span>
                        </span>
                    </div>
                    <ul>
                        <li style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                                <strong>
                                To respond to user inquiries/offer support to users.{" "}
                                </strong>
                                We may process your information to respond to your inquiries
                                and solve any potential issues you might have with the
                                requested service.
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                            </span>
                        </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                        </bdt>
                        </div>
                        <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                <strong>
                                    To send administrative information to you.{" "}
                                </strong>
                                We may process your information to send you details about
                                our products and services, changes to our terms and
                                policies, and other similar information.
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span
                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                    >
                                    <span data-custom-class="body_text">
                                        <span
                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                        >
                                        <span
                                            style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            <span data-custom-class="body_text">
                                            <bdt className="statement-end-if-in-editor" />
                                            </span>
                                        </span>
                                        </span>
                                    </span>
                                    </span>
                                </span>
                                </span>
                            </span>
                            </span>
                        </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                            </span>
                            </bdt>
                        </div>
                        <ul>
                            <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                <strong>
                                    To <bdt className="block-component" />
                                    fulfil
                                    <bdt className="else-block" /> and manage your orders.
                                </strong>{" "}
                                We may process your information to{" "}
                                <bdt className="block-component" />
                                fulfil
                                <bdt className="else-block" /> and manage your orders,
                                payments, returns, and exchanges made through the
                                Services.
                                </span>
                            </span>
                            <bdt className="statement-end-if-in-editor">
                                <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                                </span>
                            </bdt>
                            </li>
                        </ul>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                            </bdt>
                        </p>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                            </bdt>
                        </p>
                        <ul>
                            <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                    <strong>
                                        To enable user-to-user communications.{" "}
                                    </strong>
                                    We may process your information if you choose to use
                                    any of our offerings that allow for communication with
                                    another user.
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor" />
                                                </span>
                                                </span>
                                            </span>
                                            </span>
                                        </span>
                                        </span>
                                    </span>
                                    </span>
                                </span>
                                </span>
                            </span>
                            </li>
                        </ul>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component" />
                        </p>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component" />
                        </p>
                        <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                            </span>
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: 15 }}>
                                    <bdt className="block-component">
                                    <span data-custom-class="body_text" />
                                    </bdt>
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                    </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                        </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                        </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                            </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                            </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                                </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                </span>
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                    </span>
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text" />
                                                    </span>
                                                    </bdt>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text" />
                                                        </span>
                                                    </bdt>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                    </div>
                                                    <ul>
                                                        <li
                                                        data-custom-class="body_text"
                                                        style={{ lineHeight: "1.5" }}
                                                        >
                                                        <span data-custom-class="body_text">
                                                            <span style={{ fontSize: 15 }}>
                                                            <strong>
                                                                To save or protect an
                                                                individual's vital interest.
                                                            </strong>{" "}
                                                            We may process your
                                                            information when necessary to
                                                            save or protect an
                                                            individual’s vital interest,
                                                            such as to prevent harm.
                                                            </span>
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor">
                                                            <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                            </span>
                                                        </bdt>
                                                        </li>
                                                    </ul>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div
                                                        id="legalbases"
                                                        style={{ lineHeight: "1.5" }}
                                                    >
                                                        <strong>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="heading_1">
                                                            3. WHAT LEGAL BASES DO WE RELY
                                                            ON TO PROCESS YOUR
                                                            INFORMATION?
                                                            </span>
                                                        </span>
                                                        </strong>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <em>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text">
                                                            <strong>In Short: </strong>We
                                                            only process your personal
                                                            information when we believe it
                                                            is necessary and we have a
                                                            valid legal reason (i.e.
                                                            <bdt className="block-component" />{" "}
                                                            legal basis) to do so under
                                                            applicable law, like with your
                                                            consent, to comply with laws,
                                                            to provide you with services
                                                            to enter into or{" "}
                                                            <bdt className="block-component" />
                                                            fulfil
                                                            <bdt className="else-block" />{" "}
                                                            our contractual obligations,
                                                            to protect your rights, or to{" "}
                                                            <bdt className="block-component" />
                                                            fulfil
                                                            <bdt className="else-block" />{" "}
                                                            our legitimate business
                                                            interests.
                                                            </span>
                                                        </span>
                                                        </em>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            The General Data Protection
                                                            Regulation (GDPR) and UK GDPR
                                                            require us to explain the valid
                                                            legal bases we rely on in order
                                                            to process your personal
                                                            information. As such, we may
                                                            rely on the following legal
                                                            bases to process your personal
                                                            information:
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <ul>
                                                        <li
                                                        data-custom-class="body_text"
                                                        style={{ lineHeight: "1.5" }}
                                                        >
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text">
                                                            <strong>Consent. </strong>We
                                                            may process your information
                                                            if you have given us
                                                            permission (i.e.
                                                            <bdt className="block-component" />{" "}
                                                            consent) to use your personal
                                                            information for a specific
                                                            purpose. You can withdraw your
                                                            consent at any time. Learn
                                                            more about{" "}
                                                            </span>
                                                        </span>
                                                        <a
                                                            data-custom-class="link"
                                                            href="#withdrawconsent"
                                                        >
                                                            <span
                                                            style={{
                                                                color: "rgb(0, 58, 250)",
                                                                fontSize: 15
                                                            }}
                                                            >
                                                            <span data-custom-class="body_text">
                                                                withdrawing your consent
                                                            </span>
                                                            </span>
                                                        </a>
                                                        <span data-custom-class="body_text">
                                                            .
                                                        </span>
                                                        </li>
                                                    </ul>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                    </div>
                                                    <ul>
                                                        <li
                                                        data-custom-class="body_text"
                                                        style={{ lineHeight: "1.5" }}
                                                        >
                                                        <span data-custom-class="body_text">
                                                            <span style={{ fontSize: 15 }}>
                                                            <strong>
                                                                Performance of a Contract.
                                                            </strong>{" "}
                                                            We may process your personal
                                                            information when we believe it
                                                            is necessary to{" "}
                                                            <bdt className="block-component" />
                                                            fulfil
                                                            <bdt className="else-block" />{" "}
                                                            our contractual obligations to
                                                            you, including providing our
                                                            Services or at your request
                                                            prior to entering into a
                                                            contract with you.
                                                            </span>
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor">
                                                            <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                            </span>
                                                        </bdt>
                                                        </li>
                                                    </ul>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                    </div>
                                                    <ul>
                                                        <li
                                                        data-custom-class="body_text"
                                                        style={{ lineHeight: "1.5" }}
                                                        >
                                                        <span data-custom-class="body_text">
                                                            <span style={{ fontSize: 15 }}>
                                                            <strong>
                                                                Legal Obligations.
                                                            </strong>{" "}
                                                            We may process your
                                                            information where we believe
                                                            it is necessary for compliance
                                                            with our legal obligations,
                                                            such as to cooperate with a
                                                            law enforcement body or
                                                            regulatory agency, exercise or
                                                            defend our legal rights, or
                                                            disclose your information as
                                                            evidence in litigation in
                                                            which we are involved.
                                                            <bdt className="statement-end-if-in-editor" />
                                                            <br />
                                                            </span>
                                                        </span>
                                                        </li>
                                                    </ul>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                    </div>
                                                    <ul>
                                                        <li
                                                        data-custom-class="body_text"
                                                        style={{ lineHeight: "1.5" }}
                                                        >
                                                        <span data-custom-class="body_text">
                                                            <span style={{ fontSize: 15 }}>
                                                            <strong>
                                                                Vital Interests.
                                                            </strong>{" "}
                                                            We may process your
                                                            information where we believe
                                                            it is necessary to protect
                                                            your vital interests or the
                                                            vital interests of a third
                                                            party, such as situations
                                                            involving potential threats to
                                                            the safety of any person.
                                                            </span>
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor">
                                                            <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                            </span>
                                                        </bdt>
                                                        </li>
                                                    </ul>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                        <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15 }}>
                                                            In legal terms, we are generally
                                                            the{" "}
                                                            <bdt className="block-component" />
                                                            'data controller'
                                                            <bdt className="else-block" />{" "}
                                                            under European data protection
                                                            laws of the personal information
                                                            described in this privacy
                                                            notice, since we determine the
                                                            means and/or purposes of the
                                                            data processing we perform. This
                                                            privacy notice does not apply to
                                                            the personal information we
                                                            process as a{" "}
                                                            <bdt className="block-component" />
                                                            'data processor'
                                                            <bdt className="else-block" />{" "}
                                                            on behalf of our customers. In
                                                            those situations, the customer
                                                            that we provide services to and
                                                            with whom we have entered into a
                                                            data processing agreement is the{" "}
                                                            <bdt className="block-component" />
                                                            'data controller'
                                                            <bdt className="else-block" />{" "}
                                                            responsible for your personal
                                                            information, and we merely
                                                            process your information on
                                                            their behalf in accordance with
                                                            your instructions. If you want
                                                            to know more about our
                                                            customers' privacy practices,
                                                            you should read their privacy
                                                            policies and direct any
                                                            questions you have to them.
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="statement-end-if-in-editor">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                        <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15 }}>
                                                            <bdt className="block-component">
                                                            <bdt className="block-component" />
                                                            </bdt>
                                                        </span>
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                        </bdt>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div
                                                        id="whoshare"
                                                        style={{ lineHeight: "1.5" }}
                                                    >
                                                        <span
                                                        style={{
                                                            color: "rgb(127, 127, 127)"
                                                        }}
                                                        >
                                                        <span
                                                            style={{
                                                            color: "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                            }}
                                                        >
                                                            <span
                                                            style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                            >
                                                            <span
                                                                style={{
                                                                fontSize: 15,
                                                                color: "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                                <span
                                                                id="control"
                                                                style={{
                                                                    color: "rgb(0, 0, 0)"
                                                                }}
                                                                >
                                                                <strong>
                                                                    <span data-custom-class="heading_1">
                                                                    4. WHEN AND WITH WHOM
                                                                    DO WE SHARE YOUR
                                                                    PERSONAL INFORMATION?
                                                                    </span>
                                                                </strong>
                                                                </span>
                                                            </span>
                                                            </span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span
                                                        style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                        >
                                                        <span
                                                            style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            <span data-custom-class="body_text">
                                                            <strong>
                                                                <em>In Short:</em>
                                                            </strong>
                                                            <em>
                                                                {" "}
                                                                We may share information in
                                                                specific situations
                                                                described in this section
                                                                and/or with the following{" "}
                                                                <bdt className="block-component" />
                                                                third parties.
                                                            </em>
                                                            </span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span
                                                        style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                        >
                                                        <span
                                                            style={{
                                                            fontSize: 15,
                                                            color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            We{" "}
                                                            <bdt className="block-component" />
                                                            may need to share your personal
                                                            information in the following
                                                            situations:
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <ul>
                                                        <li
                                                        data-custom-class="body_text"
                                                        style={{ lineHeight: "1.5" }}
                                                        >
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text">
                                                            <strong>
                                                                Business Transfers.
                                                            </strong>{" "}
                                                            We may share or transfer your
                                                            information in connection
                                                            with, or during negotiations
                                                            of, any merger, sale of
                                                            company assets, financing, or
                                                            acquisition of all or a
                                                            portion of our business to
                                                            another company.
                                                            </span>
                                                        </span>
                                                        </li>
                                                    </ul>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                        </span>
                                                        </span>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                            <bdt className="block-component">
                                                            <span data-custom-class="body_text" />
                                                            </bdt>
                                                        </span>
                                                        <div
                                                            style={{ lineHeight: "1.5" }}
                                                        >
                                                            <bdt className="block-component">
                                                            <span
                                                                style={{ fontSize: 15 }}
                                                            >
                                                                <span data-custom-class="body_text" />
                                                            </span>
                                                            </bdt>
                                                        </div>
                                                        <ul>
                                                            <li
                                                            data-custom-class="body_text"
                                                            style={{ lineHeight: "1.5" }}
                                                            >
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <strong>
                                                                    Business Partners.
                                                                </strong>{" "}
                                                                We may share your
                                                                information with our
                                                                business partners to offer
                                                                you certain products,
                                                                services, or promotions.
                                                                </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text" />
                                                                </span>
                                                            </bdt>
                                                            </li>
                                                        </ul>
                                                        <div
                                                            style={{ lineHeight: "1.5" }}
                                                        >
                                                            <bdt className="block-component">
                                                            <span
                                                                style={{ fontSize: 15 }}
                                                            >
                                                                <span data-custom-class="body_text" />
                                                            </span>
                                                            </bdt>
                                                            <div
                                                            style={{ lineHeight: "1.5" }}
                                                            >
                                                            <bdt className="block-component">
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text" />
                                                                </span>
                                                            </bdt>
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <bdt className="block-component" />
                                                                </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text" />
                                                                </span>
                                                            </bdt>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <bdt className="block-component">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                                </bdt>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <strong>
                                                                <span
                                                                    id="3pwebsites"
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="heading_1">
                                                                    5. WHAT IS OUR STANCE
                                                                    ON THIRD-PARTY
                                                                    WEBSITES?
                                                                    </span>
                                                                </span>
                                                                </strong>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                    <em>In Short:</em>
                                                                    </strong>
                                                                    <em>
                                                                    {" "}
                                                                    We are not responsible
                                                                    for the safety of any
                                                                    information that you
                                                                    share with third
                                                                    parties that we may
                                                                    link to or who
                                                                    advertise on our
                                                                    Services, but are not
                                                                    affiliated with, our
                                                                    Services.
                                                                    </em>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    The Services
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    may link to third-party
                                                                    websites, online
                                                                    services, or mobile
                                                                    applications and/or
                                                                    contain advertisements
                                                                    from third parties that
                                                                    are not affiliated with
                                                                    us and which may link to
                                                                    other websites,
                                                                    services, or
                                                                    applications.
                                                                    Accordingly, we do not
                                                                    make any guarantee
                                                                    regarding any such third
                                                                    parties, and we will not
                                                                    be liable for any loss
                                                                    or damage caused by the
                                                                    use of such third-party
                                                                    websites, services, or
                                                                    applications. The
                                                                    inclusion of a link
                                                                    towards a third-party
                                                                    website, service, or
                                                                    application does not
                                                                    imply an endorsement by
                                                                    us. We cannot guarantee
                                                                    the safety and privacy
                                                                    of data you provide to
                                                                    any third parties. Any
                                                                    data collected by third
                                                                    parties is not covered
                                                                    by this privacy notice.
                                                                    We are not responsible
                                                                    for the content or
                                                                    privacy and security
                                                                    practices and policies
                                                                    of any third parties,
                                                                    including other
                                                                    websites, services, or
                                                                    applications that may be
                                                                    linked to or from the
                                                                    Services. You should
                                                                    review the policies of
                                                                    such third parties and
                                                                    contact them directly to
                                                                    respond to your
                                                                    questions.
                                                                </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                                </bdt>
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                }}
                                                                >
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <bdt className="block-component">
                                                                            <span data-custom-class="heading_1">
                                                                            <bdt className="block-component" />
                                                                            </span>
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        color:
                                                                        "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                        >
                                                                        <span
                                                                            style={{
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                            style={{
                                                                                fontSize: 15
                                                                            }}
                                                                            >
                                                                            <span
                                                                                style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                                </span>
                                                                                <bdt className="block-component">
                                                                                <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                                </bdt>
                                                                            </span>
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="inforetain"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(127, 127, 127)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        id="control"
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 0, 0)"
                                                                        }}
                                                                        >
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                            6. HOW LONG DO
                                                                            WE KEEP YOUR
                                                                            INFORMATION?
                                                                            </span>
                                                                        </strong>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <strong>
                                                                        <em>In Short: </em>
                                                                    </strong>
                                                                    <em>
                                                                        We keep your
                                                                        information for as
                                                                        long as necessary to{" "}
                                                                        <bdt className="block-component" />
                                                                        fulfil
                                                                        <bdt className="else-block" />{" "}
                                                                        the purposes
                                                                        outlined in this
                                                                        privacy notice
                                                                        unless otherwise
                                                                        required by law.
                                                                    </em>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    We will only keep your
                                                                    personal information
                                                                    for as long as it is
                                                                    necessary for the
                                                                    purposes set out in
                                                                    this privacy notice,
                                                                    unless a longer
                                                                    retention period is
                                                                    required or permitted
                                                                    by law (such as tax,
                                                                    accounting, or other
                                                                    legal requirements).
                                                                    <bdt className="block-component" />{" "}
                                                                    No purpose in this
                                                                    notice will require us
                                                                    keeping your personal
                                                                    information for longer
                                                                    than{" "}
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    <bdt className="block-component" />
                                                                    <bdt className="question">
                                                                        six (6)
                                                                    </bdt>{" "}
                                                                    months past the
                                                                    termination of the
                                                                    user's account{" "}
                                                                    <bdt className="block-component" />
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="else-block" />
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    .
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    When we have no
                                                                    ongoing legitimate
                                                                    business need to
                                                                    process your personal
                                                                    information, we will
                                                                    either delete or{" "}
                                                                    <bdt className="block-component" />
                                                                    anonymise
                                                                    <bdt className="else-block" />{" "}
                                                                    such information, or,
                                                                    if this is not
                                                                    possible (for example,
                                                                    because your personal
                                                                    information has been
                                                                    stored in backup
                                                                    archives), then we
                                                                    will securely store
                                                                    your personal
                                                                    information and
                                                                    isolate it from any
                                                                    further processing
                                                                    until deletion is
                                                                    possible.
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="infosafe"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(127, 127, 127)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        id="control"
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 0, 0)"
                                                                        }}
                                                                        >
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                            7. HOW DO WE
                                                                            KEEP YOUR
                                                                            INFORMATION
                                                                            SAFE?
                                                                            </span>
                                                                        </strong>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <strong>
                                                                        <em>In Short: </em>
                                                                    </strong>
                                                                    <em>
                                                                        We aim to protect
                                                                        your personal
                                                                        information through
                                                                        a system of{" "}
                                                                        <bdt className="block-component" />
                                                                        organisational
                                                                        <bdt className="else-block" />{" "}
                                                                        and technical
                                                                        security measures.
                                                                    </em>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    We have implemented
                                                                    appropriate and
                                                                    reasonable technical
                                                                    and{" "}
                                                                    <bdt className="block-component" />
                                                                    organisational
                                                                    <bdt className="else-block" />{" "}
                                                                    security measures
                                                                    designed to protect
                                                                    the security of any
                                                                    personal information
                                                                    we process. However,
                                                                    despite our safeguards
                                                                    and efforts to secure
                                                                    your information, no
                                                                    electronic
                                                                    transmission over the
                                                                    Internet or
                                                                    information storage
                                                                    technology can be
                                                                    guaranteed to be 100%
                                                                    secure, so we cannot
                                                                    promise or guarantee
                                                                    that hackers,
                                                                    cybercriminals, or
                                                                    other{" "}
                                                                    <bdt className="block-component" />
                                                                    unauthorised
                                                                    <bdt className="else-block" />{" "}
                                                                    third parties will not
                                                                    be able to defeat our
                                                                    security and
                                                                    improperly collect,
                                                                    access, steal, or
                                                                    modify your
                                                                    information. Although
                                                                    we will do our best to
                                                                    protect your personal
                                                                    information,
                                                                    transmission of
                                                                    personal information
                                                                    to and from our
                                                                    Services is at your
                                                                    own risk. You should
                                                                    only access the
                                                                    Services within a
                                                                    secure environment.
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="privacyrights"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(127, 127, 127)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        id="control"
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 0, 0)"
                                                                        }}
                                                                        >
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                            8. WHAT ARE
                                                                            YOUR PRIVACY
                                                                            RIGHTS?
                                                                            </span>
                                                                        </strong>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <strong>
                                                                        <em>In Short:</em>
                                                                    </strong>
                                                                    <em>
                                                                        {" "}
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <span
                                                                            style={{
                                                                            fontSize: 15
                                                                            }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                            <em>
                                                                                <bdt className="block-component" />
                                                                            </em>
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                        <bdt className="block-component" />
                                                                        In{" "}
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        some regions, such
                                                                        as{" "}
                                                                        <bdt className="block-component" />
                                                                        the European
                                                                        Economic Area (EEA),
                                                                        United Kingdom (UK),
                                                                        and Switzerland
                                                                        <bdt className="block-component" />
                                                                        , you have rights
                                                                        that allow you
                                                                        greater access to
                                                                        and control over
                                                                        your personal
                                                                        information.
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <span
                                                                            style={{
                                                                            fontSize: 15
                                                                            }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                            <em>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </em>
                                                                            </span>
                                                                        </span>{" "}
                                                                        </span>
                                                                        You may review,
                                                                        change, or terminate
                                                                        your account at any
                                                                        time, depending on
                                                                        your country,
                                                                        province, or state
                                                                        of residence.
                                                                    </em>
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                        >
                                                                        <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    In some regions (like{" "}
                                                                    <bdt className="block-component" />
                                                                    the EEA, UK, and
                                                                    Switzerland
                                                                    <bdt className="block-component" />
                                                                    ), you have certain
                                                                    rights under
                                                                    applicable data
                                                                    protection laws. These
                                                                    may include the right
                                                                    (i) to request access
                                                                    and obtain a copy of
                                                                    your personal
                                                                    information, (ii) to
                                                                    request rectification
                                                                    or erasure; (iii) to
                                                                    restrict the
                                                                    processing of your
                                                                    personal information;
                                                                    (iv) if applicable, to
                                                                    data portability; and
                                                                    (v) not to be subject
                                                                    to automated
                                                                    decision-making. In
                                                                    certain circumstances,
                                                                    you may also have the
                                                                    right to object to the
                                                                    processing of your
                                                                    personal information.
                                                                    You can make such a
                                                                    request by contacting
                                                                    us by using the
                                                                    contact details
                                                                    provided in the
                                                                    section{" "}
                                                                    <bdt className="block-component" />
                                                                    '
                                                                    <bdt className="else-block" />
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <a
                                                                data-custom-class="link"
                                                                href="#contact"
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(0, 58, 250)"
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(0, 58, 250)"
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        HOW CAN YOU CONTACT
                                                                        US ABOUT THIS
                                                                        NOTICE?
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </a>
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                    '
                                                                    <bdt className="else-block" />{" "}
                                                                    below.
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    We will consider and
                                                                    act upon any request
                                                                    in accordance with
                                                                    applicable data
                                                                    protection laws.
                                                                    <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                {" "}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    If you are located in
                                                                    the EEA or UK and you
                                                                    believe we are
                                                                    unlawfully processing
                                                                    your personal
                                                                    information, you also
                                                                    have the right to
                                                                    complain to your{" "}
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 58, 250)"
                                                                        }}
                                                                        >
                                                                        <span data-custom-class="body_text">
                                                                            <span
                                                                            style={{
                                                                                color:
                                                                                "rgb(0, 58, 250)"
                                                                            }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                                <a
                                                                                data-custom-class="link"
                                                                                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                >
                                                                                <span
                                                                                    style={{
                                                                                    fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    Member
                                                                                    State
                                                                                    data
                                                                                    protection
                                                                                    authority
                                                                                </span>
                                                                                </a>
                                                                            </span>
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                    </span>{" "}
                                                                    or{" "}
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <a
                                                                data-custom-class="link"
                                                                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(0, 58, 250)"
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(0, 58, 250)"
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        UK data protection
                                                                        authority
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </a>
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    .
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    If you are located in
                                                                    Switzerland, you may
                                                                    contact the{" "}
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 58, 250)"
                                                                        }}
                                                                        >
                                                                        <span data-custom-class="body_text">
                                                                            <span
                                                                            style={{
                                                                                color:
                                                                                "rgb(0, 58, 250)"
                                                                            }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                style={{
                                                                                    color:
                                                                                    "rgb(0, 58, 250)",
                                                                                    fontSize: 15
                                                                                }}
                                                                                >
                                                                                <a
                                                                                    data-custom-class="link"
                                                                                    href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                    rel="noopener noreferrer"
                                                                                    target="_blank"
                                                                                >
                                                                                    Federal
                                                                                    Data
                                                                                    Protection
                                                                                    and
                                                                                    Information
                                                                                    Commissioner
                                                                                </a>
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    .
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="withdrawconsent"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <strong>
                                                                        <u>
                                                                        Withdrawing your
                                                                        consent:
                                                                        </u>
                                                                    </strong>{" "}
                                                                    If we are relying on
                                                                    your consent to
                                                                    process your personal
                                                                    information,
                                                                    <bdt className="block-component" />
                                                                    <bdt className="else-block" />{" "}
                                                                    you have the right to
                                                                    withdraw your consent
                                                                    at any time. You can
                                                                    withdraw your consent
                                                                    at any time by
                                                                    contacting us by using
                                                                    the contact details
                                                                    provided in the
                                                                    section{" "}
                                                                    <bdt className="block-component" />
                                                                    '
                                                                    <bdt className="else-block" />
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <a
                                                                data-custom-class="link"
                                                                href="#contact"
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(0, 58, 250)"
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(0, 58, 250)"
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        HOW CAN YOU CONTACT
                                                                        US ABOUT THIS
                                                                        NOTICE?
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </a>
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                    '
                                                                    <bdt className="else-block" />{" "}
                                                                    below
                                                                    <bdt className="block-component" />
                                                                    .
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    However, please note
                                                                    that this will not
                                                                    affect the lawfulness of
                                                                    the processing before
                                                                    its withdrawal nor,
                                                                    <bdt className="block-component" />
                                                                    <bdt className="else-block" />{" "}
                                                                    will it affect the
                                                                    processing of your
                                                                    personal information
                                                                    conducted in reliance on
                                                                    lawful processing
                                                                    grounds other than
                                                                    consent.
                                                                    <bdt className="block-component" />
                                                                </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                                </bdt>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="heading_2">
                                                                    <strong>
                                                                    Account Information
                                                                    </strong>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    If you would at any time
                                                                    like to review or change
                                                                    the information in your
                                                                    account or terminate
                                                                    your account, you can:
                                                                    <bdt className="forloop-component" />
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <ul>
                                                                <li
                                                                data-custom-class="body_text"
                                                                style={{
                                                                    lineHeight: "1.5"
                                                                }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <bdt className="question">
                                                                        Contact us using the
                                                                        contact information
                                                                        provided.
                                                                    </bdt>
                                                                    </span>
                                                                </span>
                                                                </li>
                                                            </ul>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <bdt className="forloop-component" />
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    Upon your request to
                                                                    terminate your account,
                                                                    we will deactivate or
                                                                    delete your account and
                                                                    information from our
                                                                    active databases.
                                                                    However, we may retain
                                                                    some information in our
                                                                    files to prevent fraud,
                                                                    troubleshoot problems,
                                                                    assist with any
                                                                    investigations, enforce
                                                                    our legal terms and/or
                                                                    comply with applicable
                                                                    legal requirements.
                                                                </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                                </bdt>
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <span
                                                                            style={{
                                                                            fontSize: 15
                                                                            }}
                                                                        >
                                                                            <span
                                                                            style={{
                                                                                color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                                >
                                                                                <span
                                                                                    style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                                </bdt>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    If you have questions or
                                                                    comments about your
                                                                    privacy rights, you may
                                                                    email us at{" "}
                                                                    <bdt className="question">
                                                                    info@soliseco.com
                                                                    </bdt>
                                                                    .
                                                                </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                                </bdt>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="DNT"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(127, 127, 127)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        id="control"
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 0, 0)"
                                                                        }}
                                                                        >
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                            9. CONTROLS
                                                                            FOR
                                                                            DO-NOT-TRACK
                                                                            FEATURES
                                                                            </span>
                                                                        </strong>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    Most web browsers and
                                                                    some mobile operating
                                                                    systems and mobile
                                                                    applications include a
                                                                    Do-Not-Track (
                                                                    <bdt className="block-component" />
                                                                    'DNT'
                                                                    <bdt className="else-block" />
                                                                    ) feature or setting
                                                                    you can activate to
                                                                    signal your privacy
                                                                    preference not to have
                                                                    data about your online
                                                                    browsing activities
                                                                    monitored and
                                                                    collected. At this
                                                                    stage, no uniform
                                                                    technology standard
                                                                    for{" "}
                                                                    <bdt className="block-component" />
                                                                    recognising
                                                                    <bdt className="else-block" />{" "}
                                                                    and implementing DNT
                                                                    signals has been{" "}
                                                                    <bdt className="block-component" />
                                                                    finalised
                                                                    <bdt className="else-block" />
                                                                    . As such, we do not
                                                                    currently respond to
                                                                    DNT browser signals or
                                                                    any other mechanism
                                                                    that automatically
                                                                    communicates your
                                                                    choice not to be
                                                                    tracked online. If a
                                                                    standard for online
                                                                    tracking is adopted
                                                                    that we must follow in
                                                                    the future, we will
                                                                    inform you about that
                                                                    practice in a revised
                                                                    version of this
                                                                    privacy notice.
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                />
                                                                </bdt>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                />
                                                                </bdt>
                                                                <bdt className="block-component">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                />
                                                                </bdt>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="policyupdates"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(127, 127, 127)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        id="control"
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 0, 0)"
                                                                        }}
                                                                        >
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                            10. DO WE MAKE
                                                                            UPDATES TO
                                                                            THIS NOTICE?
                                                                            </span>
                                                                        </strong>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <em>
                                                                        <strong>
                                                                        In Short:{" "}
                                                                        </strong>
                                                                        Yes, we will update
                                                                        this notice as
                                                                        necessary to stay
                                                                        compliant with
                                                                        relevant laws.
                                                                    </em>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    We may update this
                                                                    privacy notice from
                                                                    time to time. The
                                                                    updated version will
                                                                    be indicated by an
                                                                    updated{" "}
                                                                    <bdt className="block-component" />
                                                                    'Revised'
                                                                    <bdt className="else-block" />{" "}
                                                                    date at the top of
                                                                    this privacy notice.
                                                                    If we make material
                                                                    changes to this
                                                                    privacy notice, we may
                                                                    notify you either by
                                                                    prominently posting a
                                                                    notice of such changes
                                                                    or by directly sending
                                                                    you a notification. We
                                                                    encourage you to
                                                                    review this privacy
                                                                    notice frequently to
                                                                    be informed of how we
                                                                    are protecting your
                                                                    information.
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="contact"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(127, 127, 127)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        id="control"
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 0, 0)"
                                                                        }}
                                                                        >
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                            11. HOW CAN
                                                                            YOU CONTACT US
                                                                            ABOUT THIS
                                                                            NOTICE?
                                                                            </span>
                                                                        </strong>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    If you have questions
                                                                    or comments about this
                                                                    notice, you may{" "}
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="block-component">
                                                                            <bdt className="block-component" />
                                                                        </bdt>
                                                                        email us at{" "}
                                                                        <bdt className="question">
                                                                            info@soliseco.com
                                                                            or{" "}
                                                                        </bdt>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                            <bdt className="block-component" />
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <span data-custom-class="body_text">
                                                                            contact us by
                                                                            post at:
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <span
                                                                            style={{
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                            <bdt className="question">
                                                                                Metop
                                                                                Engineering
                                                                                SRL
                                                                            </bdt>
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                    26 Clos du Diable
                                                                    Vert, 1390
                                                                    Grez-Doiceau, Belgium
                                                                    </bdt>
                                                                    <span
                                                                    style={{
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                    Grez-Doiceau
                                                                    </bdt>
                                                                    <span
                                                                    style={{
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <bdt className="block-component" />
                                                                        ,{" "}
                                                                        <bdt className="question">
                                                                        Région Wallonne
                                                                        </bdt>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        <bdt className="block-component" />{" "}
                                                                        <bdt className="question">
                                                                        1390
                                                                        </bdt>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                    <bdt className="question">
                                                                    Belgium
                                                                    </bdt>
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <bdt className="statement-end-if-in-editor">
                                                                            <span
                                                                            style={{
                                                                                fontSize: 15
                                                                            }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                style={{
                                                                                    color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                                >
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                    <span
                                                                                    style={{
                                                                                        fontSize: 15
                                                                                    }}
                                                                                    >
                                                                                    <span data-custom-class="body_text">
                                                                                        <span
                                                                                        style={{
                                                                                            color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                        >
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                    </span>
                                                                                </bdt>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                            <span
                                                                                style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                                >
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        <span
                                                                        style={{
                                                                            color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                        >
                                                                        <bdt className="statement-end-if-in-editor">
                                                                            <span
                                                                            style={{
                                                                                color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                            >
                                                                            <span
                                                                                style={{
                                                                                fontSize: 15
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                data-custom-class="body_text"
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                If you are a resident in
                                                                the European Economic Area
                                                                <bdt className="block-component" />{" "}
                                                                or Switzerland
                                                                <bdt className="statement-end-if-in-editor" />
                                                                , we are the{" "}
                                                                <bdt className="block-component" />
                                                                'data controller'
                                                                <bdt className="else-block" />{" "}
                                                                of your personal
                                                                information. We have
                                                                appointed{" "}
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                        Metop Engineering
                                                                        SRL
                                                                    </bdt>
                                                                    </span>
                                                                </span>{" "}
                                                                to be our representative
                                                                in the EEA
                                                                <bdt className="block-component" />{" "}
                                                                and Switzerland
                                                                <bdt className="statement-end-if-in-editor" />
                                                                . You can contact them
                                                                directly regarding our
                                                                processing of your
                                                                information
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    ,
                                                                    <bdt className="block-component" />{" "}
                                                                    by email at{" "}
                                                                    <bdt className="question">
                                                                        info@soliseco.com
                                                                    </bdt>
                                                                    ,
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />{" "}
                                                                    by visiting{" "}
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(0, 58, 250)"
                                                                        }}
                                                                    >
                                                                        <bdt className="question">
                                                                        <a
                                                                            href="http://www.soliseco.com"
                                                                            target="_blank"
                                                                            data-custom-class="link"
                                                                        >
                                                                            http://www.soliseco.com
                                                                        </a>
                                                                        </bdt>
                                                                    </span>
                                                                    ,
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />
                                                                    </span>
                                                                </span>{" "}
                                                                or by post to:
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                            <bdt className="statement-end-if-in-editor">
                                                                            <bdt className="block-component" />
                                                                            </bdt>
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                    <bdt className="block-component" />
                                                                    </bdt>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                    clos du diable vert 26
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                        color:
                                                                            "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="block-component">
                                                                            <bdt className="block-component" />
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    </bdt>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                        Grez-Doiceau
                                                                        <span
                                                                            style={{
                                                                            fontSize: 15
                                                                            }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                            <span
                                                                                style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                </bdt>
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                        </span>
                                                                        </bdt>
                                                                    </span>
                                                                    </span>
                                                                    <span
                                                                    style={{
                                                                        color:
                                                                        "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                    </span>
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        ,{" "}
                                                                    </span>
                                                                    </span>
                                                                    <span
                                                                    style={{
                                                                        color:
                                                                        "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                        Brabant-Wallon
                                                                        </bdt>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor">
                                                                        <bdt className="block-component" />
                                                                        </bdt>
                                                                    </span>
                                                                    </span>
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        {" "}
                                                                        <bdt className="question">
                                                                        1390
                                                                        <span
                                                                            style={{
                                                                            fontSize: 15
                                                                            }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                            <span
                                                                                style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                        </span>
                                                                        <bdt className="block-component" />
                                                                        </bdt>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <bdt className="block-component">
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                                </bdt>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                    >
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                        Belgium
                                                                        </bdt>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <span
                                                                data-custom-class="body_text"
                                                                style={{ fontSize: 15 }}
                                                                >
                                                                <span
                                                                    style={{ fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </bdt>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                            <bdt className="statement-end-if-in-editor">
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <span
                                                                                data-custom-class="body_text"
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                                >
                                                                                <span
                                                                                    style={{
                                                                                    fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                        fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                        <bdt className="question">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                <bdt className="block-component" />
                                                                                            </bdt>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                    </span>
                                                                                </span>
                                                                                </span>
                                                                            </bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                        </span>
                                                                        <bdt className="block-component">
                                                                        <span
                                                                            style={{
                                                                            fontSize: 15
                                                                            }}
                                                                        />
                                                                        </bdt>
                                                                        <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                        >
                                                                        <span data-custom-class="body_text">
                                                                            <span
                                                                            style={{
                                                                                color:
                                                                                "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                            }}
                                                                            >
                                                                            <span
                                                                                style={{
                                                                                fontSize: 15
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                    <bdt className="block-component" />
                                                                                </bdt>
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                id="request"
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    color:
                                                                    "rgb(127, 127, 127)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                    }}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                        id="control"
                                                                        style={{
                                                                            color:
                                                                            "rgb(0, 0, 0)"
                                                                        }}
                                                                        >
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                            12. HOW CAN
                                                                            YOU REVIEW,
                                                                            UPDATE, OR
                                                                            DELETE THE
                                                                            DATA WE
                                                                            COLLECT FROM
                                                                            YOU?
                                                                            </span>
                                                                        </strong>
                                                                        </span>
                                                                    </span>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <br />
                                                            </div>
                                                            <div
                                                                style={{
                                                                lineHeight: "1.5"
                                                                }}
                                                            >
                                                                <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                                >
                                                                <span
                                                                    style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                    Based on the
                                                                    applicable laws of
                                                                    your country
                                                                    <bdt className="block-component" />
                                                                    , you may
                                                                    <bdt className="else-block">
                                                                        <bdt className="block-component">
                                                                        {" "}
                                                                        have the right to
                                                                        request access to
                                                                        the personal
                                                                        information we
                                                                        collect from you,
                                                                        details about how
                                                                        we have processed
                                                                        it, correct
                                                                        inaccuracies, or
                                                                        delete your
                                                                        personal
                                                                        information. You
                                                                        may also have the
                                                                        right to withdraw
                                                                        your consent to
                                                                        our processing of
                                                                        your personal
                                                                        information. These
                                                                        rights may be
                                                                        limited in some
                                                                        circumstances by
                                                                        applicable law. To
                                                                        request to review,
                                                                        update, or delete
                                                                        your personal
                                                                        information,
                                                                        please contact:
                                                                        info@soliseco.com
                                                                        <bdt className="block-component" />
                                                                        </bdt>
                                                                    </bdt>
                                                                    </span>
                                                                </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                <span data-custom-class="body_text" />
                                                                </bdt>
                                                                <span data-custom-class="body_text">
                                                                .
                                                                </span>
                                                            </div>
                                                            <style
                                                                dangerouslySetInnerHTML={{
                                                                __html: "\n"
                                                                }}
                                                            />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
	);
};

export default PrivacyPolicy;
