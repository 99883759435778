import React, { ReactNode, useEffect } from "react";
import { createContext, useState } from "react";
import { getData, storeData } from "../helpers/store_data";
import axios, {
  set_instance_token,
  unset_instance_token,
} from "../services/axios";
import axios_auth from "../services/axios_auth";
import { useTranslation } from "react-i18next";

const default_user = {
  email: "",
  first_name: "",
  last_name: "",
  is_login: false,
  token: null,
  language: "en",
};

type User = {
  email: string;
  first_name: string;
  last_name: string;
  is_login: boolean;
  token: string | null;
  language: string;
};

type AuthContextType = {
  user: User;
  login: (email: string, password: string) => Promise<boolean>;
  register: (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    password_confirmation: string
  ) => Promise<{}[]>;
  logout: () => void;
  update_user: (data: any) => Promise<any>;
};

const initialAuthContextValue: AuthContextType = {
  user: { ...default_user },
  login: async (email: string, password: string) => true,
  register: async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    password_confirmation: string
  ) => [true, {}],
  logout: () => {},
  update_user: async (data: any) => [],
};

const AuthContext = createContext(initialAuthContextValue);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState({ ...default_user });
  const [loaded, setLoaded] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation("messages");

  const login = async (email: string, password: string) => {
    const login_req = await axios_auth
      .post("/login/", {
        email: email,
        password: password,
      })
      .then((e) => e.data)
      .catch(() => null);

    if (login_req) {
      const token = login_req.key;
      set_instance_token(token);
      storeData("token", token);
      setUser({ ...user, token: token, is_login: true });
      profile(token);
      return true;
    }
    return false;
  };

  const profile = async (token: any) => {
    const profile_data = await axios
      .get("/dj-rest-auth/user/")
      .then((e) => e.data)
      .catch((e) => null);

    if (profile_data) {
      storeData("user", profile_data);
      i18n.changeLanguage(profile_data.language);
      setUser({
        ...user,
        email: profile_data.email,
        first_name: profile_data.first_name,
        last_name: profile_data.last_name,
        language: profile_data.language,
        token: token,
        is_login: true,
      });
    } else {
    }
  };

  const register = async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    password_confirmation: string
  ) => {
    let reason = {};
    const register_req = await axios_auth
      .post("/registration/", {
        first_name: first_name,
        last_name: last_name,
        username: email,
        email: email,
        password1: password,
        password2: password_confirmation,
      })
      .then((e) => e.data)
      .catch((e) => {
        if (e.response?.data) reason = e.response.data;
        return null;
      });

    if (register_req !== null) {
      return [true, {}];
    }
    return [false, reason];
  };

  const update_user = async (data: any) => {
    let message = {};
    const updated = await axios
      .patch("/dj-rest-auth/user/", data)
      .then((e) => e.data)
      .catch((e) => {
        return null;
      });

    if (updated) {
      i18n.changeLanguage(updated.language);
      storeData("user", updated);
      setUser({
        ...user,
        email: updated.email,
        first_name: updated.first_name,
        last_name: updated.last_name,
        language: updated.language,
      });
      message = {
        message: t("auth_profile_updated_successfully_message"),
        description: t("auth_profile_updated_successfully_description"),
        type: "success",
      };
    } else {
      message = {
        message: t("auth_profile_updated_error_message"),
        description: t("auth_profile_updated_error_description"),
        type: "error",
      };
    }

    if (data.current_password && data.new_password) {
      const update_password = await axios
        .post("/dj-rest-auth/password/change/", {
          new_password1: data.new_password,
          new_password2: data.new_password,
          old_password: data.current_password,
        })
        .then((e) => e.data)
        .catch((e) => {
          if (e.response.data["new_password2"]) {
            message = {
              message: t("auth_update_password_error_message"),
              description: t("auth_update_password_error_description"),
              type: "error",
            };
          }
          return null;
        });

      if (update_password) {
        message = {
          message: t("auth_update_password_success_message"),
          description: t("auth_update_password_success_description"),
          type: "success",
        };
      }
    }

    return message;
  };

  const setup = async () => {
    const app_data = getData();
    if (app_data.token) {
      let udpated_user = {
        ...user,
        token: app_data.token,
        is_login: true,
      };
      if (app_data.user) {
        i18n.changeLanguage(app_data.user.language);
        udpated_user = {
          ...udpated_user,
          email: app_data.user.email,
          first_name: app_data.user.first_name,
          last_name: app_data.user.last_name,
          language: app_data.user.language,
        };
      }
      set_instance_token(app_data.token);
      setUser(udpated_user);
      profile(app_data.token);
    } else {
      // logged out
    }
    setLoaded(true);
  };

  useEffect(() => {
    setup();
  }, []);

  const logout = async () => {
    setUser({ ...default_user, is_login: false });
    localStorage.clear();
    unset_instance_token();
  };

  if (!loaded) return null;

  return (
    <AuthContext.Provider
      value={{
        user,
        register,
        login,
        logout,
        update_user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
