import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthDecorator from "../../components/auth/auth_decorator/AuthDecorator";
import MainBtn from "../../components/main/main_btn/MainBtn";
import RegsitrationOkIcon from "../../assets/images/registration-successful.svg";
import { useTranslation } from "react-i18next";

const RegisterOk = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();

  return (
    <div className="auth-page main-page">
      <section>
        <div className="container">
          <div className="form-container">
            <img
              className="success-icon"
              alt="Success icon"
              src={RegsitrationOkIcon}
            />
            <h1>{t("successful_registration")}</h1>
            <p className="main-description">
              {t("successful_registration_description")}
            </p>
            <MainBtn
              title="Vers la page de connexion"
              onClick={() => navigate("/auth/login")}
            />

            <p style={{ marginTop: "40px" }} className="swap-auth-text">
              {t("need_additional_help")}{" "}
              <Link className="primary-link" to={"mailto:info@soliseco.be"}>
                {t("contact_our_help")}
              </Link>
            </p>
          </div>
          <AuthDecorator />
        </div>
      </section>
    </div>
  );
};

export default RegisterOk;
