import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

interface DevicesData {}

export const fetchDevices = createAsyncThunk(
  "devices/fetchDevices",
  async () => {
    return await axios.get("/api-v1/device/").then((resp) => resp.data).catch((err) => []);
  }
);

export const createDevice = createAsyncThunk(
  "devices/createDevice",
  async (data: any) => {
    return data;
  }
);

export const updateDevice = createAsyncThunk(
  "devices/updateDevice",
  async ({
    id,
    data,
    updated = false,
  }: {
    id: number;
    data: any;
    updated: boolean;
  }) => {
    if (updated) {
      return data;
    }
    const response = await axios.patch(`/api-v1/device/${id}/`, data);
    return response.data;
  }
);

export const deleteDevice = createAsyncThunk(
  "devices/deleteDevice",
  async (id: number) => {
    await axios.delete(`/api-v1/device/${id}/`);
    return id;
  }
);

const devicesSlice = createSlice({
  name: "devices",
  initialState: null as DevicesData | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDevices.fulfilled, (state, { payload }) => {
      payload.sort((a: any, b: any) => a.id - b.id);
      return payload;
    });
    builder.addCase(createDevice.fulfilled, (state: any, { payload }) => {
      return [...state, payload];
    });
    builder.addCase(updateDevice.fulfilled, (state: any, { payload }) => {
      const updated = [...state];
      const old = updated.find((e) => e.id === payload.id);
      if (old) {
        updated.splice(updated.indexOf(old), 1, payload);
      } else {
        return [...state, payload];
      }
      return updated;
    });
    builder.addCase(deleteDevice.fulfilled, (state: any, { payload }) => {
      const updated = [...state];
      const old = updated.find((e) => e.id === payload);
      if (old) {
        updated.splice(updated.indexOf(old), 1);
      }
      return updated;
    });
  },
});

export const devicesMethods = {
  fetchDevices,
  createDevice,
  updateDevice,
  deleteDevice,
};

export default devicesSlice.reducer;
