import axios from "axios";
import { baseURL } from "./axios";

const instance = axios.create({
	baseURL: `${baseURL}/dj-rest-auth`,
	headers: {
		"Content-Type": "application/json",
	},
	timeout: 6000,
});

export default instance;
