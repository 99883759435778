import React, { useEffect, useMemo, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "@mui/material";
import MainBtn from "../../main/main_btn/MainBtn";
import { App, Input, Select } from "antd";
import { Home } from "../../../lib/Home";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { DispatchProps, StateProps } from "../../../store";
import { Device } from "../../../lib/Device";
import { useTranslation } from "react-i18next";
import axios from "../../../services/axios";
import { runes } from 'runes2';

const empty_schedule = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];

const CreateDeviceModal = ({
  open = false,
  close = () => null,
  device,
  homes,
  devices,
  createDevice,
}: {
  open: boolean;
  close: () => void;
  device: number;
  homes: Home[];
  devices: Device[];
  createDevice: any;
}) => {
  const { t } = useTranslation("home");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    home: 0,
    equipment_type: "soliseco_1p",
    device_id: "",
    device_secret: "",
    name: "",
  });
  const { notification } = App.useApp();

  const create_device = async () => {
    setLoading(true);
    let error: any | null = null;
    const response = await axios
      .post(`/api-v1/device/`, {
        name: formData.name,
        home: formData.home,
        device_id: formData.device_id,
        secret: formData.device_secret,
        schedule: [...empty_schedule],
      })
      .then((e) => e.data)
      .catch((e) => {
        error = e.response.data;
        return null;
      });
    if (response) {
      createDevice(response);
      close();
    } else {
      if (error["device_id"]) {
        notification.error({
          message: t("messages:create_home_error_message"),
          description: t("messages:create_home_error_bad_id"),
          placement: "topLeft",
        });
      } else if (error["secret"]) {
        notification.error({
          message: t("messages:create_home_error_message"),
          description: t("messages:create_home_error_bad_secret"),
          placement: "topLeft",
        });
      }
    }
    setLoading(false);
  };

  const device_exist = useMemo(() => {
    if (!devices || !devices.length) return null;
    const exist = devices.filter(
      (device) =>
        device.name === formData.name || device.device_id === formData.device_id
    );
    if (exist.length) return exist[0];
    return null;
  }, [devices, formData]);

  useEffect(() => {
    if (open) {
      setFormData({
        home: device > 0 ? device : homes[0].id,
        equipment_type: "soliseco_1p",
        device_id: "",
        device_secret: "",
        name: "",
      });
    }
  }, [open]);

  return (
    <Modal className="soliseco-modal" open={open} onClose={close}>
      <div className="modal-center">
        <div className="modal-header">
          <div className="close-container" onClick={close}>
            <CloseOutlined />
          </div>
          <span className="title">{t("new_device_modal_title")}</span>
          <p>{t("new_device_modal_description")}</p>
        </div>
        <div className="modal-content">
          <form className="classic-form">
            <div className="input-block">
              <label htmlFor="country-selector">{t("home_label")}</label>
              <Select
                id="country-selector"
                value={formData.home}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    home: e,
                  })
                }
              >
                {homes?.map((home: Home, key: number) => (
                  <Select.Option key={key} value={home.id}>
                    {home.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="input-block">
              <label htmlFor="country-selector">{t("device_type_label")}</label>
              <Select
                id="country-selector"
                value={formData.equipment_type}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    equipment_type: e,
                  })
                }
              >
                <Select.Option value={"soliseco_1p"}>Soliseco 1P</Select.Option>
              </Select>
            </div>
            <div className="input-block">
              <label htmlFor="device-id-input">{t("device_id_label")}</label>
              <Input
                size="small"
                value={formData.device_id}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    device_id: e.target.value,
                  })
                }
                status={
                  device_exist && device_exist.device_id === formData.device_id
                    ? "error"
                    : ""
                }
                placeholder={t("device_id_placeholder")}
                type="text"
                autoComplete="given-name"
                id="device-id-input"
              />
            </div>
            <div className="input-block">
              <label htmlFor="device-secret-input">
                {t("device_secret_label")}
              </label>
              <Input
                size="small"
                value={formData.device_secret}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    device_secret: e.target.value,
                  })
                }
                placeholder={t("device_secret_placeholder")}
                type="text"
                autoComplete="given-name"
                id="device-secret-input"
              />
            </div>
            <div className="input-block">
              <label htmlFor="device-name-input">
                {t("device_name_label")}
              </label>
              <Input
                size="small"
                value={formData.name}
                status={
                  device_exist && device_exist.name === formData.name
                    ? "error"
                    : ""
                }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  })
                }
                count={{
                  show: true,
                  max: 25,
                  strategy: (txt) => runes(txt).length,
                  exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                }}
                placeholder={t("device_name_placeholder")}
                type="text"
                autoComplete="given-name"
                id="device-name-input"
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <MainBtn
            title={t("add_device_btn")}
            loading={loading}
            onClick={create_device}
            disabled={
              formData.equipment_type === "" ||
              formData.device_id === "" ||
              formData.name === "" ||
              device_exist !== null
            }
          />
          <MainBtn title={t("main:cancel")} color="secondary" onClick={close} />
        </div>
      </div>
    </Modal>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateDeviceModal);
