import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import AuthDecorator from "../../components/auth/auth_decorator/AuthDecorator";
import { useTranslation } from "react-i18next";

const NeedVerifyEmail = () => {
  const { t } = useTranslation("auth");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  return (
    <div className="auth-page main-page">
      <section>
        <div className="container">
          <div className="form-container">
            <div
              onClick={() => navigate(-1)}
              className="go-back-btn arrow-hover-move"
            >
              <LeftOutlined className="arrow" />
              <span>{t("go_back")}</span>
            </div>
            <h1>{t("email_need_verification")}</h1>
            <p
              className="main-description"
              dangerouslySetInnerHTML={{
                __html: t("email_has_been_send", {
                  email: searchParams.get("email"),
                }),
              }}
            />
            <p style={{ marginTop: "40px" }} className="swap-auth-text">
              {t("need_additional_help")}{" "}
              <Link className="primary-link" to={"mailto:info@soliseco.be"}>
                {t("contact_our_help")}
              </Link>
            </p>
          </div>
          <AuthDecorator />
        </div>
      </section>
    </div>
  );
};

export default NeedVerifyEmail;
