import { useEffect, useRef, useState } from "react";
import MainBtn from "../../main/main_btn/MainBtn";
import "./style.scss";
import { DispatchProps, StateProps } from "../../../store";
import { mapDispatchToProps } from "../../../store/dispatcher";
import { connect } from "react-redux";
import { Device } from "../../../lib/Device";
import { App, Button, Input, Popconfirm, Select, Switch, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import axios from "../../../services/axios";
import { InfoCircleOutlined, QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


const getDeviceData = (device: Device) => {
  return [
    {
      type: "section",
      title: "device_settings_section_installation",
    },
    {
      type: "value",
      key: "installation_type",
      data_type: "select",
      label: "device_settings_field_installation_type",
      tooltip: "device_settings_field_installation_type_tooltip",
      values: [
        {
          key: "WATER_HEATER_MODE",
          label: "device_settings_field_installation_type_water_heater_mode",
        },
        {
          key: "HEATER_MODE",
          label: "device_settings_field_installation_type_heater_mode",
        },
      ],
    },
    {
      type: "value",
      key: "installation_phase",
      data_type: "number",
      label: "device_settings_field_installation_phase",
      tooltip: "device_settings_field_installation_phase_tooltip",
      editable: false,
    },
    {
      type: "section",
      title: "device_settings_section_configuration",
    },
    // {
    //   type: "value",
    //   key: "mode",
    //   data_type: "select",
    //   label: "device_settings_field_mode",
    //   tooltip: "device_settings_field_mode_tooltip",
    //   values: [
    //     {
    //       key: "SOLO",
    //       label: "device_settings_field_mode_solo",
    //     },
    //     {
    //       key: "DUO",
    //       label: "device_settings_field_mode_duo",
    //     },
    //   ],
    // },
    {
      type: "value",
      key: "running_state",
      data_type: "select",
      label: "device_settings_field_running_state",
      tooltip: "device_settings_field_running_state_tooltip",
      values: [
        {
          key: "BOOST",
          label: "device_settings_field_running_state_boost",
        },
        {
          key: "NORMAL",
          label: "device_settings_field_running_state_normal",
        },
        {
          key: "SLEEP",
          label: "device_settings_field_running_state_sleep",
        },
      ],
    },
    // {
    //   type: "value",
    //   key: "sanitation_max_period",
    //   data_type: "number",
    //   label: "device_settings_field_sanitation_max_period",
    //   tooltip: "device_settings_field_sanitation_max_period_tooltip",
    //   unit: "jours",
    // },
    {
      type: "value",
      key: "water_heater_min_power",
      data_type: "number",
      label: "device_settings_field_water_heater_min_power",
      tooltip: "device_settings_field_water_heater_min_power_tooltip",
      unit: "W",
    },
    {
      type: "value",
      key: "water_heater_max_power",
      data_type: "number",
      label: "device_settings_field_water_heater_max_power",
      tooltip: "device_settings_field_water_heater_max_power_tooltip",
      unit: "W",
    },
    {
      type: "value",
      key: "boost_percentage",
      data_type: "number",
      label: "device_settings_field_boost_percentage",
      tooltip: "device_settings_field_boost_percentage_tooltip",
      unit: "%",
    },
    {
      type: "section",
      title: "device_settings_section_general_data",
    },
    {
      type: "value",
      key: "name",
      data_type: "string",
      label: "device_settings_field_device_name",
    },
    {
      type: "value",
      key: "device_id",
      data_type: "string",
      label: "device_settings_field_device_id",
      editable: false,
    },
    {
      type: "value",
      key: "own_address_ip",
      data_type: "string",
      label: "device_settings_field_own_address_ip",
      editable: false,
    },
    {
      type: "section",
      title: "Dropout",
    },
    {
      type: "warning",
      message: "device_settings_incorrect_phase_devices",
      isActive: (device: Device, currentFormData: any) => {
        return  currentFormData.handle_dropout === "true" && device.dropout_phase_correct === false
      }
    },
    {
      type: "value",
      key: "handle_dropout",
      data_type: "boolean",
      label: "device_settings_field_handle_dropout",
      tooltip: "device_settings_field_handle_dropout_tooltip",

    },
    {
      type: "value",
      key: "dropout_period_start_time",
      data_type: "string",
      label: "device_settings_field_dropout_period_start",
      tooltip: "device_settings_field_dropout_period_start_tooltip",
    },
    {
      type: "value",
      key: "dropout_t_max",
      data_type: "number",
      label: "device_settings_field_dropout_t_max",
      tooltip: "device_settings_field_dropout_t_max_tooltip",
      unit: "°C",
    },
    {
      type: "value",
      key: "dropout_v_max",
      data_type: "number",
      allow_float: true,
      label: "device_settings_field_dropout_v_max",
      tooltip: "device_settings_field_dropout_v_max_tooltip",
      unit: "V",
    },
    {
      type: "value",
      key: "dropout_power",
      data_type: "number",
      label: "device_settings_field_dropout_power",
      tooltip: "device_settings_field_dropout_power_tooltip",
      unit: "W",
    },
    {
      type: "value",
      key: "dropout_period_end_time",
      data_type: "string",
      label: "device_settings_field_dropout_period_end",
      tooltip: "device_settings_field_dropout_period_end_tooltip",
    },
    {
      type: "section",
      title: "device_settings_section_temperatures",
    },
    {
      type: "value",
      key: "t_cold",
      data_type: "number",
      label: "device_settings_field_t_cold",
      unit: "°C",
    },
    {
      type: "value",
      key: "t_lukewarm",
      data_type: "number",
      label: "device_settings_field_t_lukewarm",
      unit: "°C",
    },
    {
      type: "value",
      key: "t_hot",
      data_type: "number",
      label: "device_settings_field_t_hot",
      unit: "°C",
    },
    {
      type: "value",
      key: "t_very_hot",
      data_type: "number",
      label: "device_settings_field_t_very_hot",
      unit: "°C",
    },
    {
      type: "section",
      title: "device_settings_section_firmware",
    },
    {
      type: "value",
      key: "firmware_automatic_update",
      data_type: "boolean",
      label: "device_settings_field_firmware_automatic_update",
      tooltip: "device_settings_field_firmware_automatic_update_tooltip",
    },
  ];
}

export const generate_from_device = (format: any, device: any) => {
  const values = format.filter((e: any) => e.type === "value");
  const state: any = {};
  for (let index = 0; index < values.length; index++) {
    const value = values[index];
    state[value.key] = device[value.key] !== null ? device[value.key].toString() : "";
  }

  return state;
};

export const generate_for_device = (format: any, data: any) => {
  const values = format.filter((e: any) => e.type === "value");
  const state: any = {};

  for (let index = 0; index < values.length; index++) {
    const value = values[index];

    if (value.data_type === "number") {
      if (value.allow_float === true) {
        state[value.key] = parseFloat(data[value.key]);
      } else {
        state[value.key] = parseInt(data[value.key]);
      }
    } else if (value.data_type === "boolean") {
      state[value.key] = data[value.key] === "true" ? true : false;
    } else {
      state[value.key] = data[value.key];
    }
  }

  return state;
};

const DeviceSettings = ({
  device,
  updateDevice,
  deleteDevice,
}: {
  device: Device;
  updateDevice: any;
  deleteDevice: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [formData, setFormData] = useState<any | null>(null);
  const base_data = useRef<string | null>(null);
  const { t } = useTranslation(["settings", "messages"]);
  const { notification } = App.useApp();
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setErrors([]);
  }, [formData]);

  useEffect(() => {
    const data = generate_from_device(getDeviceData(device), device);
    setFormData(data);
    setErrors([]);
    base_data.current = JSON.stringify(data);
  }, [device]);

  const cancel = () => {
    const data = generate_from_device(getDeviceData(device), device);
    setFormData(data);
    setErrors([]);
    base_data.current = JSON.stringify(data);
  };

  const save = async () => {
    setLoading(true);
    let error: any | null = null;
    const response = await axios
      .patch(
        `/api-v1/device/${device.id}/`,
        generate_for_device(getDeviceData(device), formData)
      )
      .then((e) => e.data)
      .catch((e) => {
        error = e.response.data;
        return null;
      });

    if (response) {
      updateDevice({
        id: device.id,
        data: response,
        updated: true,
      });
      notification.success({
        message: t("messages:settings_update_success_message"),
        placement: "topLeft",
      });
      setErrors([]);
    } else {
      if (error.length == undefined) {
        const error_lst = [];
        for (const [key, value] of Object.entries(error)) {
          error_lst.push(key);
          const updated: any = value;
          const error_message = getDeviceData(device).find((d) => d.key == key)?.label;
          if (updated.includes("This field may not be blank.")) {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_cannot_be_empty"),
              placement: "topLeft",
            });
          } else if (
            updated.includes("Ensure this value is greater than or equal to 5.")
          ) {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_more_than_x", { value: 5 }),
              placement: "topLeft",
            });
          } else if (key == "non_field_errors") {
            notification.error({
              message: t("messages:settings_update_error_message"),
              description: value as string,
              placement: "topLeft",
            });
          } else {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_error"),
              placement: "topLeft",
            });
          }
        }
        setErrors(error_lst);
      } else {
        notification.error({
          message: t("messages:settings_update_error_message"),
          description: error[0],
          placement: "topLeft",
        });
      }
    }
    setLoading(false);
  };

  const delete_device = () => {
    setDeleteLoading(true);
    const device_name = device.name;
    deleteDevice(device.id).then(() => {
      setDeleteLoading(false);
      setDeleteOpen(false);
      notification.error({
        message: t("messages:device_deleted_message", {
          device_name: device_name,
        }),
        placement: "topLeft",
      });
      navigate(
        `/home/${device.home}/settings`
      )
    });
  };

  if (!formData) return null;

  return (
    <div className="device-home-settings">
      <div className="save-header">
        <MainBtn
          loading={loading}
          disabled={JSON.stringify(formData) === base_data.current}
          title={t("save_updates")}
          onClick={save}
        />
        <MainBtn title={t("main:cancel")} color="secondary" onClick={cancel} />
      </div>
      <div className="device-home-container">
        <div className="settings-title">
          <span>{t("settings_of_the_device", { name: device.name })}</span>
          <div className="row">
            <Button
              onClick={() =>
                navigate(
                  `/home/${device.home}/${device.id}?current-view=dashboard`
                )
              }
            >
              {t("dashboard")}
            </Button>
            <Button
              onClick={() =>
                navigate(`/home/${device.home}/${device.id}?current-view=plan`)
              }
            >
              {t("plan")}
            </Button>
          </div>
        </div>
        {getDeviceData(device).map((data: any, index: number) => {
          if (data.type === "warning" && data.isActive(device, formData) === true) {
            return (
              <div key={ index } className="section-warning">
                <WarningOutlined style={{fontSize: 18, marginRight: "0.5rem"}}/>
                <span>{t(data.message)}</span>
              </div>
            )
          }
          if (data.type === "section") {
            return (
              <div key={index} className="section-title">
                <span>{t(data.title)}</span>
              </div>
            );
          }
          if (data.data_type === "string") {
            return (
              <div key={index} className="setting-block">
                <span>
                  {t(data.label)}
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Input
                    status={errors.includes(data.key) ? "error" : ""}
                    value={formData[data.key]}
                    onChange={ (e) =>
                      setFormData({ ...formData, [data.key]: e.target.value })
                    }
                    disabled={data.editable == false}
                  />
                  {data.unit && <span className="unit">{data.unit}</span>}
                </div>
              </div>
            );
          } else if (data.data_type === "number") {
            return (
              <div key={index} className="setting-block">
                <span>
                  {t(data.label)}
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Input
                    status={errors.includes(data.key) ? "error" : ""}
                    value={formData[data.key]}
                    onChange={(e) =>
                      setFormData({ ...formData, [data.key]: e.target.value })
                    }
                    disabled={data.editable == false}
                    type="number"
                  />
                  {data.unit && <span className="unit">{data.unit}</span>}
                </div>
              </div>
            );
          } else if (data.data_type === "select" && data.values) {
            return (
              <div key={index} className="setting-block">
                <span>
                  {t(data.label)}
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Select
                    value={formData[data.key]}
                    onChange={(e) =>
                      setFormData({ ...formData, [data.key]: e })
                    }
                  >
                    {data.values.map((value: any, index: any) => (
                      <Select.Option value={value.key} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            );
          } else if (data.data_type === "boolean") {
            return (
              <div key={index} className="setting-block">
                <span>
                  {t(data.label)}
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Switch
                    value={formData[data.key] === "true"}
                    onChange={(checked) =>
                      setFormData({
                        ...formData,
                        [data.key]: checked ? "true" : "false",
                      })
                    }
                  />
                </div>
              </div>
            );
          }
        })}
        <div className="delete-container">
          <Popconfirm
            placement="topRight"
            title={t("device_settings_delete_device")}
            description={t("device_settings_home_description")}
            okButtonProps={{ loading: deleteLoading }}
            open={deleteOpen}
            onCancel={() => setDeleteOpen(false)}
            onConfirm={delete_device}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button onClick={() => setDeleteOpen(true)} danger>
              {t("device_settings_delete_button")}
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  null,
  mapDispatchToProps
)(DeviceSettings);
