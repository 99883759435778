import { useEffect, useMemo, useRef, useState } from "react";
import MainBtn from "../../main/main_btn/MainBtn";
import "./style.scss";
import { DispatchProps, StateProps } from "../../../store";
import { mapDispatchToProps } from "../../../store/dispatcher";
import { connect } from "react-redux";
import { App, Button, Input, Popconfirm, Popover, Select, Switch, Tooltip } from "antd";
import {
  generate_for_device,
  generate_from_device,
} from "./DeviceSettings";
import { Home } from "../../../lib/Home";
import { countries } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import axios from "../../../services/axios";
import { InfoCircleOutlined, QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Device } from "../../../lib/Device";
import { useNavigate } from "react-router-dom";

const device_countries = countries.map((country) => {
  return {
    key: country.code,
    label: country.name,
  };
});


const getHomeData = (devices: Device[]) => {

  const deviceOptions = devices.map((device) => {
    return {
      key: device.id.toString(),
      label: device.name
    }
  })

  const masterDeviceOptions = [{key: "", label: '-'}, ...deviceOptions]

  return [
    {
      type: "section",
      title: "home_settings_section_general_data",
    },
    {
      type: "value",
      key: "name",
      data_type: "string",
      label: "home_settings_field_home_name",
    },
    {
      type: "value",
      key: "country",
      data_type: "select",
      label: "home_settings_field_home_country",
      values: device_countries,
    },
    {
      type: "value",
      key: "city",
      data_type: "string",
      label: "home_settings_field_home_city",
    },
    {
      type: "value",
      key: "postal_code",
      data_type: "string",
      label: "home_settings_field_home_postal_code",
    },
    {
      type: "value",
      key: "street",
      data_type: "string",
      label: "home_settings_field_home_street",
    },
    {
      type: "value",
      key: "number",
      data_type: "string",
      label: "home_settings_field_home_number",
    },
    {
      type: "section",
      title: "home_settings_section_connectivity",
    },
    {
      type: "value",
      key: "dongle_ip",
      data_type: "string",
      label: "home_settings_field_dongle_ip",
      tooltip: "home_settings_field_dongle_ip_tooltip",
    },
    {
      type: "value",
      key: "dongle_refresh_frequency",
      data_type: "number",
      label: "home_settings_field_dongle_refresh_frequency",
      tooltip: "home_settings_field_dongle_refresh_frequency_tooltip",
      unit: "s",
    },
    {
      type: "value",
      key: "authorize_external_control",
      data_type: "boolean",
      label: "home_settings_field_authorize_external_control",
      tooltip: "home_settings_field_authorize_external_control_tooltip",
    },
    {
      type: "section",
      title: "home_settings_section_installation",
    },
    {
      type: "value",
      key: "inverter_connection_type",
      data_type: "select",
      label: "home_settings_field_inverter_connection_type",
      tooltip: "home_settings_field_inverter_connection_type_tooltip",
      values: [
        {
          key: "SINGLE_PHASE",
          label: "home_settings_field_inverter_connection_type_single",
        },
        {
          key: "THREE_PHASE",
          label: "home_settings_field_inverter_connection_type_tree",
        },
      ],
    },
    {
      type: "value",
      key: "inverter_power",
      data_type: "number",
      label: "home_settings_field_inverter_power",
      tooltip: "home_settings_field_inverter_power_tooltip",
      unit: "W",
    },
    {
      type: "section",
      title: "home_settings_section_capacity_pricing",
    },
    {
      type: "value",
      key: "is_capacity_price_used",
      data_type: "boolean",
      label: "home_settings_field_is_capacity_price_used",
      tooltip: "home_settings_field_is_capacity_price_used_tooltip",
    },
    {
      type: "value",
      key: "capacity_price_power_threshold",
      data_type: "number",
      label: "home_settings_field_capacity_price_power_threshold",
      tooltip: "home_settings_field_capacity_price_power_threshold_tooltip",
      unit: "W",
    },
    {
      type: "section",
      title: "home_settings_section_dual",
    },
    {
      type: "value",
      key: "percent_dual",
      data_type: "number",
      label: "home_settings_field_percent_dual",
      tooltip: "home_settings_field_percent_dual_tooltip",
      unit: "%",
    },
    {
      type: "value",
      key: "master_device",
      data_type: "select",
      label: "home_settings_field_master_device",
      tooltip: "home_settings_field_master_device_tooltip",
      values: masterDeviceOptions,
    },
  ];
}


const HomeSettings = ({
  home,
  updateHome,
  deleteHome,
  devices,
}: {
  home: Home;
  updateHome: any;
  deleteHome: any;
  devices: Device[];
}) => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [formData, setFormData] = useState<any | null>(null);
  const base_data = useRef<string | null>(null);
  const { t } = useTranslation("settings");
  const { notification } = App.useApp();
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();

  const homeData = useMemo(() => {
    if (!devices || !home) {
      return []
    }
    return getHomeData(devices.filter((device) => device.home === home.id))
  }, [devices, home])

  const existing_device = useMemo(() => {
    return devices && devices.filter((device) => device.home == home.id).length != 0;
  }, [devices, home]);

  useEffect(() => {
    if (homeData && home) {
      const data = generate_from_device(homeData, home);
      setFormData(data);
      setErrors([]);
      base_data.current = JSON.stringify(data);
    }
  }, [home, homeData]);

  const cancel = () => {
    const data = generate_from_device(homeData, home);
    setFormData(data);
    setErrors([]);
    base_data.current = JSON.stringify(data);
  };

  useEffect(() => {
    setErrors([]);
  }, [formData]);

  const save = async () => {
    setLoading(true);
    let error: any | null = null;
    const response = await axios
      .patch(
        `/api-v1/home/${home.id}/`,
        generate_for_device(homeData, formData)
      )
      .then((e) => e.data)
      .catch((e) => {
        error = e.response.data;
        return null;
      });

    if (response) {
      updateHome({
        id: home.id,
        data: response,
        updated: true,
      });
      notification.success({
        message: t("messages:settings_update_success_message"),
        placement: "topLeft",
      });
      setErrors([]);
    } else {
      if (error.length == undefined) {
        const error_lst = [];
        for (const [key, value] of Object.entries(error)) {
          error_lst.push(key);
          const updated: any = value;
          const error_message = homeData.find((d) => d.key == key)?.label;
          if (updated.includes("This field may not be blank.")) {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_cannot_be_empty"),
              placement: "topLeft",
            });
          } else if (
            updated.includes("Ensure this value is greater than or equal to 5.")
          ) {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_more_than_x", { value: 5 }),
              placement: "topLeft",
            });
          } else if (
            updated.includes("Ensure this value is greater than or equal to 0.")
          ) {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_more_than_x", { value: 0 }),
              placement: "topLeft",
            });
          } else if (
            updated.includes("Ensure this value is less than or equal to 60")
          ) {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_between_1_60"),
              placement: "topLeft",
            });
          } else if (key == "non_field_errors") {
            notification.error({
              message: t("messages:settings_update_error_message"),
              description: value as string,
              placement: "topLeft",
            });
          } else {
            notification.error({
              message: t("messages:field_update_error", {
                field_name: t(error_message ? error_message : ""),
              }),
              description: t("messages:field_error"),
              placement: "topLeft",
            });
          }
        }
        setErrors(error_lst);
      } else {
        notification.error({
          message: t("messages:settings_update_error_message"),
          description: error[0],
          placement: "topLeft",
        });
      }
    }
    setLoading(false);
  };

  const delete_home = () => {
    setDeleteLoading(true);
    const home_name = home.name;
    deleteHome(home.id).then(() => {
      setDeleteLoading(false);
      setDeleteOpen(false);
      notification.error({
        message: t("messages:home_deleted_message", {
          home_name: home_name,
        }),
        placement: "topLeft",
      });
      navigate('/')
    });
  };

  if (!formData) return null;

  return (
    <div className="device-home-settings">
      <div className="save-header">
        <MainBtn
          loading={loading}
          disabled={JSON.stringify(formData) === base_data.current}
          title={t("save_updates")}
          onClick={save}
        />
        <MainBtn title={t("main:cancel")} color="secondary" onClick={cancel} />
      </div>
      <div className="device-home-container">
        <div className="settings-title">
          <span>{t("settings_of_the_home", { name: home.name })}</span>
        </div>
        { homeData.map((data: any, index: number) => {
          if (data.type === "warning") {
            return (
              <div key={ index } className="section-warning">
                <WarningOutlined style={{fontSize: 18, marginRight: "0.5rem"}}/>
                <span>{t(data.message)}</span>
              </div>
            )
          }
          if (data.type === "section") {
            return (
              <div key={ index } className="section-title">
                <span>{t(data.title)}</span>
              </div>
            );
          }
          if (data.data_type === "string") {
            return (
              <div key={index} className="setting-block">
                <span>
                  { t(data.label) }
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Input
                    value={formData[data.key]}
                    status={errors.includes(data.key) ? "error" : ""}
                    onChange={(e) =>
                      setFormData({ ...formData, [data.key]: e.target.value })
                    }
                  />
                  {data.unit && <span className="unit">{data.unit}</span>}
                </div>
              </div>
            );
          } else if (data.data_type === "number") {
            return (
              <div key={index} className="setting-block">
                <span>
                  { t(data.label) }
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Input
                    value={formData[data.key]}
                    status={errors.includes(data.key) ? "error" : ""}
                    onChange={ (e) => {
                      setFormData({ ...formData, [data.key]: e.target.value })
                    }
                    }
                    type="number"
                  />
                  {data.unit !== null && (
                    <span className="unit">{data?.unit}</span>
                  )}
                </div>
              </div>
            );
          } else if (data.data_type === "select" && data.values) {
            return (
              <div key={index} className="setting-block">
                <span>
                  { t(data.label) }
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Select
                    value={formData[data.key]}
                    onChange={(e) =>
                      setFormData({ ...formData, [data.key]: e })
                    }
                  >
                    {data.values.map((value: any, index: number) => (
                      <Select.Option value={value.key} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            );
          } else if (data.data_type === "boolean") {
            return (
              <div key={index} className="setting-block">
                <span>
                  { t(data.label) }
                  {data.tooltip && (
                    <Tooltip title={t(data.tooltip)}>
                      <InfoCircleOutlined
                        style={{
                          color: "#012124",
                          opacity: 0.4,
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
                <div className="input-container">
                  <Switch
                    value={formData[data.key] === "true"}
                    onChange={(checked) =>
                      setFormData({
                        ...formData,
                        [data.key]: checked ? "true" : "false",
                      })
                    }
                  />
                </div>
              </div>
            );
          }
        })}
        <div className="delete-container">
          {existing_device ? (
            <Popover
              content={t("home_settings_cannot_delete_home_description")}
              placement="topRight"
              title={t("home_settings_cannot_delete_home")}
            >
              <Button disabled={true} danger>
                {t("home_settings_delete_button")}
              </Button>
            </Popover>
          ) : (
            <Popconfirm
              placement="topRight"
              title={t("home_settings_delete_home")}
              description={t("home_settings_delete_home_description")}
              open={deleteOpen}
              onConfirm={delete_home}
              onCancel={() => setDeleteOpen(false)}
              okButtonProps={{ loading: deleteLoading }}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Button onClick={() => setDeleteOpen(true)} danger>
                {t("home_settings_delete_button")}
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  null,
  mapDispatchToProps
)(HomeSettings);
