import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const DeviceNotFound = () => {
  const { t } = useTranslation("settings");
  return (
    <div className="empty-device">
      <span>{t("device_not_found")}</span>
    </div>
  );
};

export default DeviceNotFound;
