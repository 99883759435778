import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthDecorator from "../../components/auth/auth_decorator/AuthDecorator";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { App, Input } from "antd";
import MainBtn from "../../components/main/main_btn/MainBtn";
import { useTranslation } from "react-i18next";
import axios_auth from "../../services/axios_auth";

const ResetPassword = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const submit_reset = async () => {
    setLoading(true);

    const res = await axios_auth
      .post("/password/reset/", { email: formData.email })
      .then((e) => e.data)
      .catch((e) => null);

    notification.success({
      message: t("messages:email_send_message"),
      description: t("messages:email_send_description"),
      placement: "topLeft",
    });
    setFormData({ email: "" });
    setLoading(false);
  };

  return (
    <div className="auth-page main-page">
      <section>
        <div className="container">
          <div className="form-container">
            <div
              onClick={() => navigate(-1)}
              className="go-back-btn arrow-hover-move"
            >
              <ArrowBackIosIcon className="arrow" />
              <span>{t("go_back")}</span>
            </div>
            <h1>{t("reset_password")}</h1>
            <p className="main-description">
              {t("reset_password_description")}
            </p>
            <form>
              <label htmlFor="email-input">{t("email_label")}</label>
              <Input
                size="small"
                value={formData.email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
                placeholder={t("email_placeholder")}
                type="email"
                autoComplete="email"
                id="email-input"
              />

              <MainBtn
                loading={loading}
                title={t("send_link_btn")}
                onClick={submit_reset}
                disabled={formData.email === ""}
              />
            </form>

            <p className="swap-auth-text">
              {t("need_additional_help")}{" "}
              <Link className="primary-link" to={"mailto:info@soliseco.be"}>
                {t("contact_our_help")}
              </Link>
            </p>
          </div>
          <AuthDecorator />
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
