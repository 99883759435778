import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../static/images/logo.png";
import { ReactComponent as ArrowDown } from "../../../static/icones/arrow-down.svg";
import AuthContext from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { ReactComponent as NavbarHome } from "../../../assets/icons/navbar-home.svg";

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);
  const location = useLocation();
  const [openUserPortal, setOpenUserPortal] = useState(false);
  const first_click = useRef(false);
  const { t } = useTranslation("main");

  const handle_click = (e: MouseEvent) => {
    if (first_click.current) {
      setOpenUserPortal(false);
    }
    first_click.current = true;
  };

  useEffect(() => {
    if (openUserPortal) {
      document.addEventListener("click", handle_click);

      return () => {
        document.removeEventListener("click", handle_click);
        first_click.current = false;
      };
    }
  }, [openUserPortal]);

  return (
    <nav className="main-navbar">
      <Link aria-label="Soliseco home" className="home-logo" to={"/"}>
        <img alt="soliseco logo" src={logo} />
      </Link>
      <ul className="center-navigation">
        <li className={location.pathname === "/" ? "selected" : ""}>
          <Link to={"/"}>
            <NavbarHome />
            <span>{t("home")}</span>
          </Link>
        </li>
      </ul>
      <div className="user-portal-btn" onClick={() => setOpenUserPortal(true)}>
        <img
          alt="user initials icon"
          src={`https://api.dicebear.com/7.x/initials/png?seed=${user.first_name}+${user.last_name}`}
        />
        {/* <span className="user-name">
          {user.first_name} {user.last_name}
        </span> */}
        <ArrowDown />
        {openUserPortal && (
          <div className="user-portal">
            <Link to={"/account"}>{t("account")}</Link>
            <span onClick={logout}>{t("logout")}</span>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
