import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardData {}

export const fetchDashboard = createAsyncThunk(
  "dashboard/fetchDashboard",
  async () => {
    const response = { data: "hello" };
    return response.data;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: null as DashboardData | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboard.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export const dashboardMethods = {
  fetchDashboard,
};

export default dashboardSlice.reducer;
