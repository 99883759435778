import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

interface HomesData {}

export const fetchHomes = createAsyncThunk("homes/fetchHomes", async () => {
    return await axios.get("/api-v1/home/").then((resp) => resp.data).catch((err) => []);
});

export const createHome = createAsyncThunk("homes/createHome", async (data) => {
  const response = await axios.post("/api-v1/home/", data);
  return response.data;
});

export const updateHome = createAsyncThunk(
  "devices/updateHome",
  async ({
    id,
    data,
    updated = false,
  }: {
    id: number;
    data: any;
    updated: boolean;
  }) => {
    if (updated) {
      return data;
    }
    const response = await axios.patch(`/api-v1/home/${id}/`, data);
    return response.data;
  }
);

export const deleteHome = createAsyncThunk(
  "devices/deleteHome",
  async (id: number) => {
    await axios.delete(`/api-v1/home/${id}/`);
    return id;
  }
);

const homesSlice = createSlice({
  name: "homes",
  initialState: null as HomesData | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHomes.fulfilled, (state, { payload }) => {
      payload.sort((a: any, b: any) => a.id - b.id);
      return payload;
    });
    builder.addCase(createHome.fulfilled, (state: any, { payload }) => {
      return [...state, payload];
    });
    builder.addCase(updateHome.fulfilled, (state: any, { payload }) => {
      const updated = [...state];
      const old = updated.find((e) => e.id === payload.id);
      if (old) {
        updated.splice(updated.indexOf(old), 1, payload);
      } else {
        return [...state, payload];
      }
      return updated;
    });
    builder.addCase(deleteHome.fulfilled, (state: any, { payload }) => {
      const updated = [...state];
      const old = updated.find((e) => e.id === payload);
      if (old) {
        updated.splice(updated.indexOf(old), 1);
      }
      return updated;
    });
  },
});

export const homesMethods = {
  fetchHomes,
  createHome,
  updateHome,
  deleteHome,
};

export default homesSlice.reducer;
