export const storeData = (key: string, value: any) => {
  const jsonValue = JSON.stringify(value);
  localStorage.setItem(key, jsonValue);
};

export const getSpecificData = (key: string) => {
  const jsonValue = localStorage.getItem(key);
  return jsonValue !== null ? JSON.parse(jsonValue) : null;
};

type GetDataDict = {
  token: any | null;
  user: any | null;
};

export const getData = () => {
  let get_dict: GetDataDict = {
    token: null,
    user: null,
  };

  for (const key in get_dict) {
    if (Object.prototype.hasOwnProperty.call(get_dict, key)) {
      const jsonValue = localStorage.getItem(key);
      try {
        get_dict[key as keyof GetDataDict] =
          jsonValue !== null ? JSON.parse(jsonValue) : null;
      } catch {
        get_dict[key as keyof GetDataDict] = null;
      }
    }
  }
  return get_dict;
};
