import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { Modal } from "@mui/material";
import MainBtn from "../../main/main_btn/MainBtn";
import "./style.scss";
import { DispatchProps, StateProps } from "../../../store";
import { mapDispatchToProps } from "../../../store/dispatcher";
import { connect } from "react-redux";
import { Device } from "../../../lib/Device";

const PlanningModal = ({
  device,
  open,
  close,
  planning,
  updateDevice,
}: {
    device: Device;
    open: boolean;
    close: any;
    planning: number[][];
    updateDevice: any;
  }) => {
  const { t } = useTranslation("settings");
  const [loading, setLoading] = useState(false);
  const [selectedTemperature, setSelectedTemperature] = useState(0);
  
  const tempToRange: any = {
    "cold": `${device.t_cold}°C - ${device.t_lukewarm}°C`,
    "warm": `${device.t_lukewarm}°C - ${device.t_hot}°C`,
    "hot": `${device.t_hot}°C - ${device.t_very_hot}°C`,
    "very_hot": `${device.t_very_hot}°C +`
  }
  const set_temperature = () => {
    const updated = planning.map((day) =>
      day.map((hour) => {
        if (hour < 0) {
          return selectedTemperature;
        }
        return hour;
      })
    );

    setLoading(true);
    updateDevice({
      id: parseInt(device.id ? device.id.toString() : "0"),
      data: { schedule: updated },
    }).then(() => {
      setLoading(false);
      close();
    });
  };

  return (
    <Modal
      className="soliseco-modal planning-modal"
      open={open}
      onClose={close}
    >
      <div className="modal-center">
        <div className="modal-header">
          <div className="close-container" onClick={close}>
            <CloseOutlined />
          </div>
          <span className="title">{t("plan_modal_title")}</span>
        </div>
        <div className="modal-content">
          <div className="temp-container">
            {["cold", "warm", "hot", "very_hot"].map((temp, index) => (
              <div
                onClick={() => setSelectedTemperature(index)}
                key={index}
                className={`temp-block heat-${index} ${
                  index === selectedTemperature ? "selected" : ""
                }`}
              >
                <span>{ t(temp) }</span>
                <span className="temp-range"> { tempToRange[temp]}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <MainBtn title={t("cancel")} color="secondary" onClick={close} />
          <MainBtn
            title={t("confirm")}
            loading={loading}
            onClick={set_temperature}
          />
        </div>
      </div>
    </Modal>
  );
};

export default connect<StateProps, DispatchProps>(
  null,
  mapDispatchToProps
)(PlanningModal);
