import { dashboardMethods } from "./slices/dashboard";
import { homesMethods } from "./slices/homes";
import { devicesMethods } from "./slices/devices";

export const mapDispatchToProps = {
  ...dashboardMethods,
  ...devicesMethods,
  ...homesMethods,
};

export const mapStateToProps = (state: any) => state;
