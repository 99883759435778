export const countries = [
  {
    code: "BE",
    name: "Belgium",
  },
  {
    code: "DE",
    name: "Germany",
  },
  {
    code: "NL",
    name: "Netherlands",
  },
  {
    code: "LU",
    name: "Luxembourg",
  },
  {
    code: "SE",
    name: "Sweden",
  },
  {
    code: "FI",
    name: "Finland",
  },
  {
    code: "DK",
    name: "Denmark",
  },
  {
    code: "CH",
    name: "Switzerland",
  },
  {
    code: "HU",
    name: "Hungary",
  },
  {
    code: "IE",
    name: "Ireland",
  },
  {
    code: "AT",
    name: "Austria",
  },
  {
    code: "LT",
    name: "Lithuania",
  },
];

countries.sort((a, b) => a.name.localeCompare(b.name));

export const temperature_status = {
  cold: {
    color: "#479CFF",
    title: "Froid",
  },
  hot: {
    color: "#F43636",
    title: "Chaud",
  },
};
