import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthDecorator from "../../components/auth/auth_decorator/AuthDecorator";
import MainBtn from "../../components/main/main_btn/MainBtn";
import AuthContext from "../../contexts/AuthContext";
import { App, Checkbox, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const { register } = useContext(AuthContext);
  const { notification } = App.useApp();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    accept_legal: false,
  });
  const [loading, setLoading] = useState(false);

  const form_ref = useRef<HTMLFormElement | null>(null);

  const submit_register = async () => {
    setLoading(true);
    const successful = await register(
      formData.first_name,
      formData.last_name,
      formData.email,
      formData.password,
      formData.password_confirmation
    );

    if (successful[0]) {
      navigate(`/auth/email-verification?email=${formData.email}`);
    } else {
      const data: any = successful[1];
      if (data["username"]) {
        notification.error({
          message: t("messages:register_taken_message"),
          description: t("messages:register_taken_descripton"),
          placement: "topLeft",
        });
      } else if (data["non_field_errors"]) {
        notification.error({
          message: t("messages:register_same_password_message"),
          description: "",
          placement: "topLeft",
        });
      } else if (data["password1"]) {
        notification.error({
          message: t("messages:register_invalid_message"),
          description: t("messages:register_invalid_description"),
          placement: "topLeft",
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className="auth-page main-page">
      <section>
        <div className="container">
          <div className="form-container">
            <h1 dangerouslySetInnerHTML={{ __html: t("register_title") }} />
            <p className="main-description">{t("register_description")}</p>
            <form ref={form_ref}>
              <div className="input-row row">
                <div className="input-block">
                  <label htmlFor="first-name-input">
                    {t("first_name_label")}
                  </label>
                  <Input
                    required
                    size="small"
                    value={formData.first_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        first_name: e.target.value,
                      })
                    }
                    placeholder={t("first_name_placeholder")}
                    type="text"
                    autoComplete="given-name"
                    id="first-name-input"
                  />
                </div>
                <div className="input-block">
                  <label htmlFor="last-name-input">
                    {t("last_name_label")}
                  </label>
                  <Input
                    required
                    size="small"
                    value={formData.last_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        last_name: e.target.value,
                      })
                    }
                    placeholder={t("last_name_placeholder")}
                    type="text"
                    autoComplete="family-name"
                    id="last-name-input"
                  />
                </div>
              </div>
              <label htmlFor="email-input">{t("email_label")}</label>
              <Input
                required
                size="small"
                value={formData.email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
                placeholder={t("email_placeholder")}
                type="email"
                autoComplete="email"
                id="email-input"
              />
              <label htmlFor="password-input">{t("password_label")}</label>
              <Input.Password
                required
                size="small"
                value={formData.password}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  })
                }
                placeholder={t("password_placeholder")}
                type="password"
                autoComplete="password"
                id="password-input"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <label htmlFor="password-confirmation-input">
                {t("password_confirmation_label")}
              </label>
              <Input.Password
                required
                size="small"
                value={formData.password_confirmation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password_confirmation: e.target.value,
                  })
                }
                placeholder={t("password_confirmation_placeholder")}
                type="password"
                autoComplete="password"
                id="password-confirmation-input"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />

              <Checkbox
                required
                style={{ marginBottom: "10px" }}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    accept_legal: e.target.checked,
                  })
                }
              >
                {t("i_accept_the")}{" "}
                <Link
                  className="primary-link"
                  to={"/terms-conditions"}
                  target="_blank"
                >
                  {t("terms_and_conditions")}
                </Link>
                {" "}{t("and_the")}{" "}
                <Link
                  className="primary-link"
                  to={"/privacy-policy"}
                  target="_blank"
                >
                  {t("privacy_policy")}
                </Link>
              </Checkbox>

              <MainBtn
                form={form_ref}
                loading={loading}
                title={t("register_btn")}
                onClick={submit_register}
                disabled={
                  formData.email === "" ||
                  formData.password === "" ||
                  formData.first_name === "" ||
                  formData.last_name === "" ||
                  formData.password_confirmation === "" ||
                  !formData.accept_legal
                }
              />
            </form>

            <p className="swap-auth-text">
              {t("already_have_account_yet")}{" "}
              <Link className="primary-link" to={"/auth/login"}>
                {t("login_now")}
              </Link>
            </p>
          </div>
          <AuthDecorator />
        </div>
      </section>
    </div>
  );
};

export default Register;
