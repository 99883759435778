import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DispatchProps, StateProps } from "../../../store";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import "./style.scss";
import { Home } from "../../../lib/Home";
import { Device } from "../../../lib/Device";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeviceIcon } from "../../../assets/icons/device.svg";

const SettingsAsideHomeBlock = ({
  home,
  devices,
}: {
  home: Home;
  devices: Device[];
}) => {
  const { home_id, device_id } = useParams();
  const { t } = useTranslation("settings");
  const [isOpen, setIsOpen] = useState(
    parseInt(home_id ? home_id : "0") === home.id ? true : false
  );

  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(parseInt(home_id ? home_id : "0") === home.id ? true : false);
  }, [home, home_id]);

  return (
    <div className={`settings-aside-home-block ${isOpen ? "open" : ""}`}>
      <div
        className={`home-block-header ${
          !device_id && parseInt(home_id ? home_id : "0") === home.id
            ? "selected"
            : ""
        }`}
        onClick={() => {
          navigate(`/home/${home.id}/settings`);
          if (
            !isOpen ||
            (!device_id && parseInt(home_id ? home_id : "0") == home.id)
          ) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div className="row">
          <HomeIcon />
          <span>{home.name}</span>
        </div>
        <Chevron
          className="chevron"
          style={{ transform: isOpen ? "" : "rotate(180deg)" }}
        />
      </div>
      {isOpen && (
        <div className="home-block-content">
          {/* <div
            className={`device-block ${
              !device_id && parseInt(home_id ? home_id : "0") === home.id
                ? "selected"
                : ""
            }`}
            onClick={() => navigate(`/home/${home.id}/settings`)}
          >
            <span>{t("home_settings")}</span>
            <Chevron
              className="chevron"
              style={{ transform: "rotate(90deg)" }}
            />
          </div> */}
          {devices.map((device: Device, index: number) => (
            <div
              onClick={() => navigate(`/home/${home.id}/${device.id}/settings`)}
              className={`device-block ${
                device_id && parseInt(device_id ? device_id : "0") === device.id
                  ? "selected"
                  : ""
              }`}
              key={index}
            >
              <div className="device-icon-name">
                <DeviceIcon />
                <span>{device.name}</span>
              </div>
              <Chevron
                className="chevron"
                style={{ transform: "rotate(90deg)" }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SettingsAside = ({
  homes,
  devices,
}: {
  homes: Home[];
  devices: Device[];
}) => {
  const { t } = useTranslation("settings");

  return (
    <aside className="settings-aside">
      <span className="aside-title">{t("items_subject_to_settings")}</span>
      {homes?.map((home: Home, index: number) => (
        <SettingsAsideHomeBlock
          key={index}
          home={home}
          devices={devices ? devices.filter((d) => d.home === home.id) : []}
        />
      ))}
    </aside>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsAside);
