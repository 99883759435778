import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import { FIELD_NAME_TO_TRANSLATION_LABEL, LineChartProps, timestampToReadable } from "./LineChartCommon";


const TensionLineChart = ({ lineData, currentDevice, ticks, lineChartXTickFormatter }: LineChartProps) => {
    
    const { t } = useTranslation("dashboard")

    const renderCustomizedTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const pt = lineData.find((elem: any) => elem.time === label);
            if (pt) {
                return (
                <div className="tooltip-container">
                    <p style={{ fontWeight: 700 }}>{timestampToReadable(parseInt(pt.time))}</p>
                    {payload.map((line: any, index: number) => (
                    <p key={index} style={{ color: line.stroke, fontWeight: 500 }}>
                        {t(line.name, { device_name: currentDevice?.name })}:{" "}
                            { pt[line.dataKey] } {line.dataKey !== "soliseco_power"  ? "V" : "W" }
                    </p>
                    ))}
                </div>
                );
            }
        }
        return <></>;
    };

    const renderColorfulLegendText = (value: string, entry: any) => {
        const { color } = entry;
        return <span style={{ color: color }}>{t(value, { device_name: currentDevice?.name })}</span>;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                style={{ marginLeft: "-10px" }}
                width={500}
                height={300}
                data={lineData}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey='time'
                    name='Time'
                    scale='time'
                    type='number'
                    domain={['dataMin', 'dataMax']} 
                    tickFormatter={ lineChartXTickFormatter }
                    ticks={ticks}
                />
                <YAxis
                    yAxisId="voltage"
                    unit="V"
                    ticks={[220, 230, 240, 250, 260]}
                    domain={ [220, 260] }
                    allowDataOverflow
                />
                <Legend formatter={renderColorfulLegendText} />
                <Tooltip content={renderCustomizedTooltip}/>
                <Legend
                    formatter={(value) =>
                    t(FIELD_NAME_TO_TRANSLATION_LABEL[value], {
                        device_name: currentDevice?.name,
                    })
                    }
                />
                <Line
                    type="monotone"
                    yAxisId="voltage"
                    dataKey="u_1"
                    stroke="#012124"
                    activeDot={{ r: 4 }}
                    dot={false}
                />
                <Line
                    type="monotone"
                    yAxisId="voltage"
                    dataKey="u_2"
                    stroke="#3b6bb8"
                    activeDot={{ r: 4 }}
                    dot={false}
                />
                <Line
                    type="monotone"
                    yAxisId="voltage"
                    dataKey="u_3"
                    stroke="#F7AA3F"
                    activeDot={{ r: 4 }}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default TensionLineChart
