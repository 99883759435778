export const darkenHexColor = (hexColor: string, factor: number = 0.5) => {
  hexColor = hexColor.replace(/^#/, "");

  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  const darkR = Math.max(0, Math.floor(r * factor));
  const darkG = Math.max(0, Math.floor(g * factor));
  const darkB = Math.max(0, Math.floor(b * factor));

  const darkHexColor = `#${darkR.toString(16).padStart(2, "0")}${darkG
    .toString(16)
    .padStart(2, "0")}${darkB.toString(16).padStart(2, "0")}`;

  return darkHexColor;
};
