import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import MainBtn from "../../main/main_btn/MainBtn";
import { Input, Select } from "antd";
import { countries } from "../../../constants/constants";
import { DispatchProps, StateProps } from "../../../store";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { Home } from "../../../lib/Home";
import { useTranslation } from "react-i18next";

const CreateHomeModal = ({
  open = false,
  close = () => null,
  createHome,
  homes,
}: {
  open: boolean;
  close: () => void;
  createHome: any;
  homes: Home[];
}) => {
  const { t } = useTranslation("home");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    country: "BE",
    city: "",
    postal_code: "",
    street: "",
    number: "",
    name: "",
  });

  const create_home = () => {
    setLoading(true);
    createHome({
      country: formData.country,
      city: formData.city,
      postal_code: formData.postal_code,
      street: formData.street,
      number: formData.number,
      name: formData.name,
    }).then(() => {
      setLoading(false);
      close();
    });
  };

  const home_exist = useMemo(() => {
    if (!homes || !homes.length) return false;
    const exist = homes.filter((home) => home.name === formData.name);
    if (exist.length) return true;
    return false;
  }, [homes, formData]);

  useEffect(() => {
    if (open) {
      setFormData({
        country: "BE",
        city: "",
        postal_code: "",
        street: "",
        number: "",
        name: "",
      });
    }
  }, [open]);

  return (
    <Modal className="soliseco-modal" open={open} onClose={close}>
      <div className="modal-center">
        <div className="modal-header">
          <div className="close-container" onClick={close}>
            <CloseOutlined />
          </div>
          <span className="title">{t("new_home_modal_title")}</span>
          <p>{t("new_home_modal_description")}</p>
        </div>
        <div className="modal-content">
          <form className="classic-form">
            <div className="input-block">
              <label className="large" htmlFor="device-name-input">
                {t("home_name_label")}
              </label>
              <Input
                size="small"
                value={formData.name}
                status={home_exist ? "error" : ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  })
                }
                placeholder={t("home_name_placeholder")}
                type="text"
                autoComplete="given-name"
                id="device-name-input"
              />
            </div>
            <div className="input-block">
              <label htmlFor="country-selector">{t("country_label")}</label>
              <Select
                id="country-selector"
                value={formData.country}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    country: e,
                  })
                }
              >
                {countries.map((country: any, key: number) => (
                  <Select.Option key={key} value={country.code}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="row">
              <div className="input-block" style={{ width: "35%" }}>
                <label htmlFor="postal-code-input">
                  {t("postal_code_label")}
                </label>
                <Input
                  size="small"
                  value={formData.postal_code}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      postal_code: e.target.value,
                    })
                  }
                  placeholder={t("postal_code_placeholder")}
                  type="text"
                  autoComplete="given-name"
                  id="postal-code-input"
                />
              </div>
              <div className="input-block">
                <label htmlFor="city-input">{t("city_label")}</label>
                <Input
                  size="small"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      city: e.target.value,
                    })
                  }
                  placeholder={t("city_placeholder")}
                  type="text"
                  autoComplete="family-name"
                  id="city-input"
                />
              </div>
            </div>
            <div className="row">
              <div className="input-block">
                <label htmlFor="street-input">{t("street_label")}</label>
                <Input
                  size="small"
                  value={formData.street}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      street: e.target.value,
                    })
                  }
                  placeholder={t("street_placeholder")}
                  type="text"
                  autoComplete="given-name"
                  id="street-input"
                />
              </div>
              <div className="input-block" style={{ width: "30%" }}>
                <label htmlFor="number-input">{t("number_label")}</label>
                <Input
                  size="small"
                  value={formData.number}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      number: e.target.value,
                    })
                  }
                  placeholder={t("number_placeholder")}
                  type="text"
                  autoComplete="family-name"
                  id="number-input"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <MainBtn
            title={t("add_home_btn")}
            loading={loading}
            onClick={create_home}
            disabled={
              formData.country === "" ||
              formData.city === "" ||
              formData.postal_code === "" ||
              formData.street === "" ||
              formData.number === "" ||
              formData.name === "" ||
              home_exist
            }
          />
          <MainBtn title={t("main:cancel")} color="secondary" onClick={close} />
        </div>
      </div>
    </Modal>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateHomeModal);
