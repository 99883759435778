import React from "react";
import DecorationLines from "../../../assets/images/auth-decorator-lines.svg";
import Logo from "../../../assets/images/logo.png";
import TopLeftLines from "../../../assets/images/top-left-lines.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";

const AuthDecorator = () => {
  const { t } = useTranslation("auth");
  return (
    <>
      <div className="auth-decorator">
        <div className="title-container">
          <div className="logo-container">
            <img src={Logo} alt="soliseco main icon" />
          </div>
          <h2>{t("auth_decorator_title")}</h2>
          <p>{t("auth_decorator_description")}</p>
        </div>
        <span className="absolute-rights">
          ©{new Date().getFullYear()} Soliseco, All rights reserved
        </span>
        <div className="decorator-block">
          <div className="decoration-block b-left"></div>
          <div className="decoration-block t-right"></div>
          <img
            className="bottom-right-lines"
            src={DecorationLines}
            alt="decoration lines"
          />
        </div>
      </div>
      <img
        className="auth-top-left-lines"
        src={TopLeftLines}
        alt="top left decoration lines"
      />
    </>
  );
};

export default AuthDecorator;
