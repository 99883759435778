import React from "react";
import "./style.scss";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { DispatchProps, StateProps } from "../../../store";
import { connect } from "react-redux";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Device } from "../../../lib/Device";
import { SettingOutlined } from "@ant-design/icons";

import { ReactComponent as Controle1 } from "../../../assets/icons/device/controle1.svg";
import { ReactComponent as Controle2 } from "../../../assets/icons/device/controle2.svg";
import { ReactComponent as Controle3 } from "../../../assets/icons/device/controle3.svg";
import { useTranslation } from "react-i18next";

const DeviceHeader = ({
  devices,
  currentView,
  setCurrentView,
  controle,
  setControle,
}: {
  devices: Device[];
  currentView: string;
  setCurrentView: any;
  controle: string;
  setControle: any;
}) => {
  const { device_id } = useParams();
  const { t } = useTranslation("settings");
  const navigate = useNavigate();

  const current_device = devices?.find(
    (device) => device.id === parseInt(device_id ? device_id : "0")
  );

  if (!current_device) {
    return null;
  }

  return (
    <div className="device-header">
      <div className="header-block">
        <span className="block-title title">{t("concerned_device")}</span>
        <div className="header-block-content">
          <Select
            value={current_device.id}
            onChange={(e) => {
              const selected = devices.find((device) => device.id === e);
              navigate(`/home/${selected?.home}/${selected?.id}`);
            }}
          >
            {devices?.map((device: Device, key: number) => (
              <Select.Option key={key} value={device.id}>
                {device.name}
              </Select.Option>
            ))}
          </Select>
          <div
            onClick={() =>
              navigate(
                `/home/${current_device.home}/${current_device.id}/settings`
              )
            }
            className="button-container"
          >
            <SettingOutlined />
          </div>
        </div>
      </div>

      <div className="header-block ">
        <span className="block-title title">{t("vue_choice")}</span>
        <div className="header-block-content">
          <div
            onClick={() => setCurrentView("dashboard")}
            className={`toggle-btn ${
              currentView === "dashboard" ? "selected" : ""
            }`}
          >
            <span>{t("dashboard")}</span>
          </div>
          <div
            onClick={() => setCurrentView("plan")}
            className={`toggle-btn ${
              currentView !== "dashboard" ? "selected" : ""
            }`}
          >
            <span>{t("plan")}</span>
          </div>
        </div>
      </div>

      <div className="header-block center fixed">
        <span className="block-title title hide">{t("controle_panel")}</span>
        <div className="header-block-content controle">
          <div
            onClick={() => setControle("BOOST")}
            className={`toggle-btn stroke ${
              controle === "BOOST" ? "selected" : ""
            }`}
          >
            <Controle1 />
            <span>Boost</span>
          </div>
          <div
            onClick={() => setControle("NORMAL")}
            className={`toggle-btn fill ${
              controle === "NORMAL" ? "selected" : ""
            }`}
          >
            <Controle2 />
            <span>Normal</span>
          </div>
          <div
            onClick={() => setControle("SLEEP")}
            className={`toggle-btn fill ${
              controle === "SLEEP" ? "selected" : ""
            }`}
          >
            <Controle3 />
            <span>Sleep</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(DeviceHeader);
