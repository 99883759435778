import { Skeleton } from "antd";
import React from "react";
import "./style.scss";

const GraphLoader = ({
  label = true,
  shape,
}: {
  label?: boolean;
  shape?: "circle" | "square";
}) => {
  return (
    <div className="graph-loader">
      <Skeleton.Avatar active className="graph-skeleton" shape={shape} />
      {label && (
        <div className="legend">
          <Skeleton.Avatar active className="legend-skeleton" shape="square" />
          <Skeleton.Avatar active className="legend-skeleton" shape="square" />
          <Skeleton.Avatar active className="legend-skeleton" shape="square" />
          <Skeleton.Avatar active className="legend-skeleton" shape="square" />
        </div>
      )}
    </div>
  );
};

export default GraphLoader;
