import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useParams } from "react-router-dom";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { DispatchProps, StateProps } from "../../../store";
import { connect } from "react-redux";
import { Device } from "../../../lib/Device";
import MainBtn from "../../main/main_btn/MainBtn";
import PlanningModal from "../planning_modal/PlanningModal";
import { useTranslation } from "react-i18next";
import { Home } from "../../../lib/Home";
import { Tooltip } from "antd";

const PlanningTile = ({
  tile,
  mouse_enter,
  previous,
  next,
  index,
}: {
  tile: number;
  mouse_enter: any;
  previous: number;
  next: number;
  index: number;
}) => {
  useEffect(() => {
    const current_tile = document.getElementById(`tile-${index}`);

    current_tile?.addEventListener("mouseenter", mouse_enter);

    return () => {
      current_tile?.removeEventListener("mouseenter", mouse_enter);
    };
  }, [index, mouse_enter]);

  const heat = tile < 0 ? tile + 10 : tile;

  return (
    <td id={`tile-${index}`}>
      <div
        className={`heat-selector  ${
          previous !== tile && !(previous < 0 && tile < 0 && previous !== -1)
            ? "left"
            : ""
        } ${
          next !== tile && !(next < 0 && tile < 0 && next !== -1) ? "right" : ""
        }`}
      >
        <div
          className={`color-item heat-${heat} ${tile < 0 ? "selected" : ""}`}
        ></div>
      </div>
    </td>
  );
};

const DevicePlanning = ({
  homes,
  devices,
}: {
  homes: Home[];
  devices: Device[];
}) => {
  const { t } = useTranslation("settings");
  const { device_id } = useParams();
  const [planning, setPlanning] = useState<number[][] | null>(null);
  const [currentDevice, setCurrentDevice] = useState<Device | null>(null);
  const fixed_planning = useRef<number[][] | null>(null);
  const current_selection = useRef<number[][] | null>(null);

  const start_selection = useRef<number | null>(null);
  const end_selection = useRef<number | null>(null);
  const selection_start = useRef(false);
  const update_after = useRef(false);

  const base_schedule = useRef<string | null>(null);

  const table_ref = useRef<HTMLTableElement | null>(null);

  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    const current_device = devices?.find(
      (device) => device.id === parseInt(device_id ? device_id : "0")
    );

    if (current_device) {
      fixed_planning.current = current_device.schedule;
      current_selection.current = fixed_planning.current;
      base_schedule.current = JSON.stringify(fixed_planning.current);
      setPlanning(current_device.schedule);
      setCurrentDevice(current_device);
    }
  }, [homes, devices, device_id]);

  const update_planning = () => {
    if (
      selection_start.current &&
      current_selection.current !== null &&
      start_selection.current !== null &&
      end_selection.current !== null
    ) {
      const [start, end] =
        start_selection.current < end_selection.current
          ? [start_selection.current, end_selection.current]
          : [end_selection.current, start_selection.current];

      const new_planning = current_selection.current.map((day, day_index) =>
        day.map((item, item_index) => {
          const real_index = day_index * day.length + item_index;

          if (start === end) return item;

          if (real_index >= start && real_index <= end) {
            return item < 0 ? item : item - 10;
          }
          if (!fixed_planning.current) return item;
          return fixed_planning.current[day_index][item_index];
        })
      );
      current_selection.current = new_planning;
      setPlanning(new_planning);
    }
  };

  const start_click = () => {
    selection_start.current = true;
    current_selection.current = fixed_planning.current;
    update_planning();
  };

  const end_click = () => {
    selection_start.current = false;
    if (
      current_selection.current &&
      start_selection.current !== null &&
      end_selection.current !== null
    ) {
      const [start, end] =
        start_selection.current < end_selection.current
          ? [start_selection.current, end_selection.current]
          : [end_selection.current, start_selection.current];
      const new_planning = current_selection.current.map((day, day_index) =>
        day.map((item, item_index) => {
          const real_index = day_index * day.length + item_index;

          if (start === end && start === real_index)
            return item < 0 ? item + 10 : item - 10;

          if (real_index >= start && real_index <= end) {
            return item;
          }
          if (!fixed_planning.current) return item;
          return fixed_planning.current[day_index][item_index];
        })
      );
      fixed_planning.current = new_planning;
    }

    update_after.current = true;

    setPlanning(fixed_planning.current);
  };

  const mouse_enter = (index: number) => {
    if (
      !start_selection.current ||
      !selection_start.current ||
      update_after.current
    ) {
      start_selection.current = index;
      update_after.current = false;
    }
    end_selection.current = index;

    update_planning();
  };

  useEffect(() => {
    const table = document.getElementById("planning-table");
    table?.addEventListener("mousedown", start_click);
    table?.addEventListener("mouseup", end_click);

    return () => {
      table?.removeEventListener("mousedown", start_click);
      table?.removeEventListener("mouseup", end_click);
    };
  }, [planning]);

  return (
    <div className="device-planning">
      {planning && currentDevice && (
        <PlanningModal
          device={currentDevice}
          planning={planning}
          open={updateModal}
          close={() => setUpdateModal(false)}
        />
      )}
      <span>{t("plan_description")}</span>
      <div className="planning-container">
        {planning && (
          <>
            <table id="planning-table" ref={table_ref}>
              <thead>
                <tr>
                  <th className="corner"></th>
                  {Array.from({ length: 24 }, (v, k) => k).map(
                    (item, index) => (
                      <th key={index}>
                        <div className="hour">
                          {item.toString().padStart(2, "00")}H
                        </div>
                      </th>
                    )
                  )}
                </tr>
              </thead>

              <tbody>
                {planning.map((day, day_index) => (
                  <tr key={day_index}>
                    <td className="no-padding">
                      <div className="day">
                        {["L", "M", "M", "J", "V", "S", "D"][day_index]}
                      </div>
                    </td>
                    {day.map((hour, hour_index) => {
                      const previous =
                        hour_index === 0 ? -1 : day[hour_index - 1];
                      const next =
                        hour_index >= day.length - 1 ? -1 : day[hour_index + 1];

                      return (
                        <PlanningTile
                          key={hour_index}
                          index={day_index * day.length + hour_index}
                          tile={hour}
                          mouse_enter={() =>
                            mouse_enter(day_index * day.length + hour_index)
                          }
                          previous={previous}
                          next={next}
                        />
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>

            {currentDevice?.handle_dropout && (
              <>
                <Tooltip title={t("dropout_period")}>
                  <table style={{ marginLeft: "30px", marginTop: "0" }}>
                    <tbody>
                      <tr>
                        {Array.from({ length: 24 }, (v, k) => k).map(
                          (item, index) => {
                            const start_dropout = parseInt(
                              currentDevice?.dropout_period_start_time.split(
                                ":"
                              )[0]
                            );

                            const end_dropout =
                              parseInt(
                                currentDevice?.dropout_period_end_time.split(
                                  ":"
                                )[0]
                              ) +
                              (currentDevice?.dropout_period_end_time.endsWith(
                                ":00:00"
                              )
                                ? -1
                                : 0);

                            return (
                              <td key={index}>
                                <div
                                  className={`heat-selector dropout ${
                                    index == 0 ||
                                    index == start_dropout ||
                                    index == end_dropout + 1
                                      ? "left"
                                      : ""
                                  } ${
                                    index == 23 ||
                                    index == end_dropout ||
                                    index == start_dropout - 1
                                      ? "right"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`color-item ${
                                      index >= start_dropout &&
                                      index <= end_dropout
                                        ? "dropout-on"
                                        : "dropout-off"
                                    }`}
                                  ></div>
                                </div>
                              </td>
                            );
                          }
                        )}
                      </tr>
                    </tbody>
                  </table>
                </Tooltip>
              </>
            )}
          </>
        )}
      </div>
      <div className="planning-footer">
        { currentDevice && <div className="legend">
          <Tooltip title={ `${currentDevice.t_cold}°C - ${currentDevice.t_lukewarm}°C` }>
            <span className="heat-0">{ t("cold") }</span>
          </Tooltip>
          <Tooltip title={ `${currentDevice.t_lukewarm}°C - ${currentDevice.t_hot}°C` }>
            <span className="heat-1">{ t("warm") }</span>
          </Tooltip>
          <Tooltip title={ `${currentDevice.t_hot}°C - ${currentDevice.t_very_hot}°C` }>
            <span className="heat-2">{ t("hot") }</span>
          </Tooltip>
          <Tooltip title={ `${currentDevice.t_very_hot}°C +` }>
            <span className="heat-3">{ t("very_hot") }</span>
          </Tooltip>
        </div>
        }
        <div className="btn-container">
          <MainBtn
            title={t("set_temperature")}
            onClick={() => setUpdateModal(true)}
            disabled={base_schedule.current === JSON.stringify(planning)}
          />
          <MainBtn
            title={t("main:cancel")}
            color="secondary"
            onClick={() => {
              if (base_schedule.current) {
                fixed_planning.current = JSON.parse(base_schedule.current);
                current_selection.current = fixed_planning.current;
                setPlanning(fixed_planning.current);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(DevicePlanning);
