import { Segmented } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const check_same_month = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() == date2.getFullYear() &&
    date1.getMonth() == date2.getMonth()
  );
};

const check_same_week = (date1: Date, date2: Date) => {
  const oneDay = 24 * 60 * 60 * 1000;

  const firstDayOfWeek = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate() - date1.getDay()
  );
  const secondDayOfWeek = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate() - date2.getDay()
  );

  const diffDays = Math.round(
    Math.abs((firstDayOfWeek.getTime() - secondDayOfWeek.getTime()) / oneDay)
  );

  return diffDays < 7;
};

const check_same_day = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() == date2.getFullYear() &&
    date1.getMonth() == date2.getMonth() &&
    date1.getDate() == date2.getDate()
  );
};

const update_date = (date: Date, type: string, add: boolean) => {
  const updatedDate = new Date(date);
  if (type == "year") {
    updatedDate.setFullYear(updatedDate.getFullYear() + (add ? 1 : -1));
  } else if (type == "month") {
    updatedDate.setMonth(updatedDate.getMonth() + (add ? 1 : -1));
  } else if (type == "week") {
    updatedDate.setDate(updatedDate.getDate() + (add ? 7 : -7));
  } else {
    updatedDate.setDate(updatedDate.getDate() + (add ? 1 : -1));
  }
  const now = new Date();
  if (
    (!check_same_day(now, date) && (type == "day" || type == "now")) ||
    (!check_same_week(now, date) && type == "week") ||
    (!check_same_month(now, date) && type == "month") ||
    (now.getFullYear() != date.getFullYear() && type == "year")
  ) {
    if (now.getTime() < updatedDate.getTime()) {
      updatedDate.setTime(now.getTime());
    }
  }
  return updatedDate;
};

const format_current_date = (date: Date, period: string) => {
  const now = new Date();
  if (check_same_day(now, date) && (period == "day" || period == "now")) {
    return "today";
  } else if (check_same_week(now, date) && period == "week") {
    return "this_week";
  } else if (check_same_month(now, date) && period == "month") {
    return "this_month";
  } else if (now.getFullYear() == date.getFullYear() && period == "year") {
    return "this_year";
  }

  const month = (date.getMonth() + 1).toString().padStart(2, "00");
  const day = date.getDate().toString().padStart(2, "00");

  if (period == "year") {
    return `${date.getFullYear()}`;
  } else if (period == "month") {
    return `${month}/${date.getFullYear()}`;
  }
  return `${day}/${month}/${date.getFullYear()}`;
};

export const generate_period = (date: Date, period: string) => {
  let start_date = new Date(date);
  let end_date = new Date(date);
  if (period == "now" || period == "day") {
  } else if (period == "week") {
    start_date.setDate(date.getDate() - date.getDay());
    end_date.setDate(start_date.getDate() + 6);
  } else if (period == "month") {
    start_date.setDate(1);
    end_date.setMonth(end_date.getMonth() + 1, 1);
    end_date.setDate(end_date.getDate() - 1);
  } else if (period == "year") {
    start_date.setMonth(0, 1);
    end_date.setFullYear(end_date.getFullYear() + 1, 0, 1);
    end_date.setDate(end_date.getDate() - 1);
  }

  const month_start = (start_date.getMonth() + 1).toString().padStart(2, "00");
  const day_start = start_date.getDate().toString().padStart(2, "00");

  const month_end = (end_date.getMonth() + 1).toString().padStart(2, "00");
  const day_end = end_date.getDate().toString().padStart(2, "00");

  return {
    start: `${start_date.getFullYear()}-${month_start}-${day_start}`,
    end: `${end_date.getFullYear()}-${month_end}-${day_end}`,
    // start_display: `${day_start}/${month_start}/${start_date.getFullYear()}`,
    // end_display: `${day_end}/${month_end}/${end_date.getFullYear()}`,
  };
};

const TimeSelector = ({ set_period, excludedPeriods }: { set_period: any, excludedPeriods?: string[] }) => {
  const { t } = useTranslation("dashboard");
  const periodOptions = [
    { value: "now", label: t("now") },
    { value: "day", label: t("day") },
    { value: "week", label: t("week") },
    { value: "month", label: t("month") },
    { value: "year", label: t("year") },
  ].filter((option) => !excludedPeriods || !excludedPeriods.includes(option.value))
  const [selectedPeriod, setSelectedPeriod] = useState("day");
  const setPeriodTimout = useRef<undefined | NodeJS.Timeout>(undefined);
  const firstLoad = useRef<boolean>(true);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const block_next =
    update_date(selectedDate, selectedPeriod, true) > new Date();

  useEffect(() => {
    if (selectedPeriod == "now") {
      setSelectedDate(new Date());
      setTimeout(() => {
        setSelectedPeriod("day");
      }, 500);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    } else {
      clearTimeout(setPeriodTimout.current);

      setPeriodTimout.current = setTimeout(() => {
        set_period(generate_period(selectedDate, selectedPeriod));
      }, 500);
    }
  }, [selectedDate, selectedPeriod]);

  return (
    <div className="time-selector">
      <div className="upper-view">
        <LeftOutlined
          onClick={() =>
            setSelectedDate(update_date(selectedDate, selectedPeriod, false))
          }
        />
        <span>{t(format_current_date(selectedDate, selectedPeriod))}</span>
        <RightOutlined
          className={block_next ? "disabled" : ""}
          onClick={() =>
            block_next
              ? null
              : setSelectedDate(update_date(selectedDate, selectedPeriod, true))
          }
        />
      </div>
      <div className="bottom-view">
        <Segmented
          options={periodOptions}
          value={selectedPeriod}
          onChange={(value) => {
            setSelectedPeriod(value);
          }}
        />
      </div>
    </div>
  );
};

export default TimeSelector;
