import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { useParams, useSearchParams } from "react-router-dom";
import DeviceHeader from "../../components/device/device_header/DeviceHeader";
import "./style.scss";
import DeviceDashboard from "../../components/device/device_dashboard/DeviceDashboard";
import DevicePlanning from "../../components/device/device_planning/DevicePlanning";
import { DispatchProps, StateProps } from "../../store";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { connect } from "react-redux";
import { Device as DeviceType } from "../../lib/Device";
import DeviceNotFound from "../../components/device/device_not_found/DeviceNotFound";

const Device = ({
  devices,
  updateDevice,
}: {
  devices: DeviceType[];
  updateDevice: any;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { home_id, device_id } = useParams();
  const current_view = searchParams.get("current-view");
  const [currentView, setCurrentView] = useState(
    current_view ? current_view : "dashboard"
  );
  const [controle, setControle] = useState("boost");

  const current_device = useMemo(
    () =>
      devices?.filter(
        (device) =>
          device.id === parseInt(device_id ? device_id : "0") &&
          device.home === parseInt(home_id ? home_id : "0")
      ),
    [device_id, devices, home_id]
  );

  const update_controle = (ctrl: string) => {
    setControle(ctrl);
    if (current_device.length) {
      updateDevice({ id: current_device[0].id, data: { running_state: ctrl } });
    }
  };

  const update_current_view = (new_view: string) => {
    setCurrentView(new_view);
    setSearchParams({ "current-view": new_view });
  };

  useEffect(() => {
    if (current_device?.length) {
      setControle(current_device[0].running_state);
    }
  }, [current_device]);

  if (current_device && current_device.length === 0) {
    return (
      <div className="device-view">
        <DeviceNotFound />
      </div>
    );
  }

  return (
    <div className="device-view">
      <DeviceHeader
        currentView={currentView}
        setCurrentView={update_current_view}
        controle={controle}
        setControle={update_controle}
      />
      <div className="device-content">
        {currentView === "dashboard" ? <DeviceDashboard /> : <DevicePlanning />}
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Device);
