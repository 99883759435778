import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultViewHeader from "../../components/main/default_view_header/DefaultViewHeader";
import AuthContext from "../../contexts/AuthContext";
import CreateHomeModal from "../../components/home/create_home_modal/CreateHomeModal";
import CreateDeviceModal from "../../components/home/create_device_modal/CreateDeviceModal";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { DispatchProps, StateProps } from "../../store";
import HomeItem from "../../components/home/home_item/HomeItem";
import "./style.scss";
import { Device } from "../../lib/Device";
import { Home as HomeType } from "../../lib/Home";
import { ReactComponent as NewHome } from "../../assets/icons/new-home.svg";

const Home = ({
  homes,
  devices,
}: {
  homes: HomeType[];
  devices: Device[];
  fetchDevices: any;
  fetchHomes: any;
}) => {
  const { t } = useTranslation("home");
  const { user } = useContext(AuthContext);
  const [createHome, setCreateHome] = useState(false);
  const [createDevice, setCreateDevice] = useState(false);

  const selected_home = useRef(-1);

  return (
    <div id="home-view">
      <CreateHomeModal open={createHome} close={() => setCreateHome(false)} />
      <CreateDeviceModal
        open={createDevice}
        device={selected_home.current}
        close={() => setCreateDevice(false)}
      />
      <DefaultViewHeader
        title={`${t("main:hello")} <span>${user.first_name}</span>,`}
        description={t("home_description")}
        big_title={true}
        action_title={t("new_home")}
        onClick={
          !homes || homes.length === 0 ? undefined : () => setCreateHome(true)
        }
      />
      <div className="homes-container">
        {homes && homes.length === 0 && (
          <div className="empty-home" onClick={() => setCreateHome(true)}>
            <NewHome />
            <span>{t("new_home_modal_title")}</span>
          </div>
        )}
        {homes?.map((home: HomeType, index: number) => (
          <HomeItem
            key={index}
            home={home}
            new_device={(home_id: number) => {
              selected_home.current = home_id;
              setCreateDevice(true);
            }}
            devices={devices ? devices.filter((d) => d.home === home.id) : []}
          />
        ))}
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Home);
