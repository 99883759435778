import React from "react";
import "./style.scss";
import { ReactComponent as NewHome } from "../../../assets/icons/new-home.svg";
import { useTranslation } from "react-i18next";

const DefaultViewHeader = ({
  title,
  description,
  big_title = false,
  onClick,
  action_title = "",
}: {
  title: string;
  description: string;
  big_title?: boolean;
  onClick?: any;
  action_title?: string;
}) => {
  const { t } = useTranslation();
  const today = new Date();

  const weekday = today.toLocaleString(t("js-date-format"), {
    weekday: "long",
  });
  const day = today.toLocaleString(t("js-date-format"), { day: "numeric" });
  const month = today.toLocaleString(t("js-date-format"), { month: "short" });

  return (
    <div className="default-view-header">
      <div className="row">
        <div className="view-title-container">
          {big_title ? (
            <h2
              className="big-title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
          ) : (
            <h2>{title}</h2>
          )}
          <p>{description}</p>
        </div>
        {onClick !== undefined && (
          <div className="new-device-btn arrow-hover-move" onClick={onClick}>
            <NewHome className="arrow" />
            <span>{action_title}</span>
          </div>
        )}
      </div>
      <div className="date-container">
        <span className="weekday">{weekday}</span>
        <span className="day-month">
          <span>{day}</span> {month}
        </span>
      </div>
    </div>
  );
};

export default DefaultViewHeader;
