import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import "./style.scss";

const MainBtn = ({
  title = "Button",
  onClick = () => null,
  variant = "outline",
  color = "primary",
  disabled = false,
  loading = false,
  form = null,
  icon = null,
}: {
  title: string;
  onClick: () => void;
  variant?: string;
  color?: string;
  disabled?: boolean;
  loading?: boolean;
  form?: MutableRefObject<HTMLFormElement | null> | null;
  icon?: any;
}) => {
  const [minDim, setMinDim] = useState({
    width: 0,
    height: 0,
  });
  const btn_ref = useRef<HTMLButtonElement | null>(null);
  const is_disabled = disabled || loading;

  useEffect(() => {
    if (!loading) {
      setMinDim({
        width: btn_ref.current?.offsetWidth ? btn_ref.current.offsetWidth : 0,
        height: btn_ref.current?.offsetHeight
          ? btn_ref.current.offsetHeight
          : 0,
      });
    }
  }, [loading]);

  const button_click = (e: any) => {
    if (!is_disabled) {
      if (!form || form.current?.checkValidity()) {
        e.preventDefault();
        onClick();
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <button
      ref={btn_ref}
      onClick={button_click}
      className={`main-btn color-${color} variant-${variant} ${
        is_disabled ? "disabled" : ""
      }`}
      style={{ minWidth: minDim.width, minHeight: minDim.height }}
    >
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className="btn-title">
          <span>{title}</span>
          {icon}
        </div>
      )}
    </button>
  );
};

export default MainBtn;
