import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios_auth from "../../services/axios_auth";
import RegsitrationOkIcon from "../../assets/images/registration-successful.svg";
import MainBtn from "../../components/main/main_btn/MainBtn";
import AuthDecorator from "../../components/auth/auth_decorator/AuthDecorator";
import { useTranslation } from "react-i18next";

const verify_email = async (key: string | null) => {
  await axios_auth
    .post("/registration/verify-email/", { key: key })
    .then((e) => e.data)
    .catch((e) => {
      return null;
    });
};

const EmailVerified = () => {
  const { t } = useTranslation("auth");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    verify_email(searchParams.get("key"));
  }, [searchParams]);

  return (
    <div className="auth-page main-page">
      <section>
        <div className="container">
          <div className="form-container">
            <img
              className="success-icon"
              alt="Success icon"
              src={RegsitrationOkIcon}
            />
            <h1>{t("successful_email_verification_title")}</h1>
            <p className="main-description">
              {t("successful_email_verification_description")}
            </p>
            <MainBtn
              title="Vers la page de connexion"
              onClick={() => navigate("/auth/login")}
            />

            <p style={{ marginTop: "40px" }} className="swap-auth-text">
              {t("need_additional_help")}{" "}
              <Link className="primary-link" to={"mailto:info@soliseco.be"}>
                {t("contact_our_help")}
              </Link>
            </p>
          </div>
          <AuthDecorator />
        </div>
      </section>
    </div>
  );
};

export default EmailVerified;
