import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AuthDecorator from "../../components/auth/auth_decorator/AuthDecorator";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { App, Input } from "antd";
import MainBtn from "../../components/main/main_btn/MainBtn";
import { useTranslation } from "react-i18next";
import axios_auth from "../../services/axios_auth";

const ResetPasswordValidation = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const submit_reset = async () => {
    setLoading(true);

    let error: any | null = null;

    if (formData.password != formData.password_confirmation) {
      notification.error({
        message: t("messages:password_resset_error_same_message"),
        description: t("messages:password_resset_error_same_description"),
        placement: "topLeft",
      });
      setLoading(false);
      return null;
    }

    const res = await axios_auth
      .post("/password/reset/confirm/", {
        uid: searchParams.get("uid"),
        token: searchParams.get("token"),
        new_password1: formData.password,
        new_password2: formData.password_confirmation,
      })
      .then((e) => true)
      .catch((e) => {
        error = e.response.data;
        return null;
      });

    if (res) {
      notification.success({
        message: t("messages:password_resset_success_message"),
        description: t("messages:password_resset_success_description"),
        placement: "topLeft",
      });
      setFormData({ password: "", password_confirmation: "" });
      navigate("/auth/login");
    } else {
      if (error["new_password2"]) {
        if (
          error["new_password2"].includes(
            "This password is too short. It must contain at least 8 characters."
          )
        ) {
          notification.error({
            message: t("messages:password_resset_error_short_message"),
            description: t("messages:password_resset_error_short_description"),
            placement: "topLeft",
          });
        }
        if (error["new_password2"].includes("This password is too common.")) {
          notification.error({
            message: t("messages:password_resset_error_common_message"),
            description: t("messages:password_resset_error_common_description"),
            placement: "topLeft",
          });
        }
      }
    }

    setLoading(false);
  };

  return (
    <div className="auth-page main-page">
      <section>
        <div className="container">
          <div className="form-container">
            <h1>{t("reset_password_validation")}</h1>
            <p className="main-description">
              {t("reset_password_validation_description")}
            </p>
            <form>
              <label htmlFor="password-input">{t("password_label")}</label>
              <Input
                size="small"
                value={formData.password}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  })
                }
                placeholder={t("password_placeholder")}
                type="password"
                id="password-input"
              />
              <label htmlFor="password-confirmation-input">
                {t("password_confirmation_label")}
              </label>
              <Input
                size="small"
                value={formData.password_confirmation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password_confirmation: e.target.value,
                  })
                }
                placeholder={t("password_confirmation_placeholder")}
                type="password"
                id="password-confirmation-input"
              />

              <MainBtn
                loading={loading}
                title={t("password_reset_validation_button")}
                onClick={submit_reset}
                disabled={
                  formData.password == "" ||
                  formData.password_confirmation == ""
                }
              />
            </form>

            <p className="swap-auth-text">
              {t("need_additional_help")}{" "}
              <Link className="primary-link" to={"mailto:info@soliseco.be"}>
                {t("contact_our_help")}
              </Link>
            </p>
          </div>
          <AuthDecorator />
        </div>
      </section>
    </div>
  );
};

export default ResetPasswordValidation;
