import React from "react";
import { Home } from "../../../lib/Home";
import "./style.scss";
import { Device } from "../../../lib/Device";
import { RightOutlined, SettingOutlined } from "@ant-design/icons";
import MainBtn from "../../main/main_btn/MainBtn";
import { useNavigate } from "react-router-dom";
import { SettingsOutlined } from "@mui/icons-material";
import { ReactComponent as NewDevice } from "../../../assets/icons/new-device.svg";
import { useTranslation } from "react-i18next";

const HomeItem = ({
  home,
  devices,
  new_device,
}: {
  home: Home;
  devices: Device[];
  new_device: (home_id: number) => void;
}) => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();
  return (
    <div className="home-item">
      <div className="home-title">{home.name}</div>
      {devices && devices.length === 0 && (
        <div className="empty-device" onClick={() => new_device(home.id)}>
          <NewDevice />
          <span>{t("new_device_modal_title")}</span>
        </div>
      )}
      {devices.map((device: Device, index: number) => (
        <div key={index} className="device-item">
          <div className="device-header">
            {/* <span
              className="temperature-status"
              style={{
                color: temperature_status["cold"].color,
                backgroundColor: darkenHexColor(
                  temperature_status["cold"].color
                ),
              }}
            >
              {temperature_status["cold"].title}
            </span> */}
            <span></span>
            <div
              className="settings-container"
              onClick={() => navigate(`/home/${home.id}/${device.id}/settings`)}
            >
              <SettingOutlined />
            </div>
          </div>
          <div className="device-center">
            <span className="device-type">Soliseco 1P</span>
            <span className="device-name-status">
              {device.name} {/* <span>Connecté</span> */}
            </span>
          </div>
          <MainBtn
            title={t("main:more_details")}
            icon={<RightOutlined />}
            onClick={() => navigate(`/home/${home.id}/${device.id}`)}
            color="on-primary"
          />
        </div>
      ))}
      <div className="btn-container">
        <div
          className="btn"
          onClick={() => navigate(`/home/${home.id}/settings`)}
        >
          <SettingsOutlined />
        </div>
        {devices?.length !== 0 && (
          <div className="btn" onClick={() => new_device(home.id)}>
            <NewDevice />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeItem;
